import React from "react";
import TermsConditions from "../TermsConditions";
import Privacy from "../Privacy";
import CloseIcon from "../../assets/img/close_mark.png";

const CommonTermsPrivacy = (props) => {
    const { TermsPravacyState, setTermsPravacyState } = props;

    return(<div className="commonModelWrappers">
        <button type="button" className="btn clsoebtnheader" onClick={() => setTermsPravacyState({...TermsPravacyState, open: false, Url: ""})}>
            <img src={CloseIcon} alt="close" />
        </button>
        {TermsPravacyState?.Url === "TERMS_CONDITIONS" ? (<TermsConditions />) : (<Privacy />)}
    </div>)
};

export default CommonTermsPrivacy;