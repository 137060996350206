/* eslint-disable */
import React, { useEffect, useState,useRef } from "react";
import BannerSection from "./BannerSection";
import PowerbySoftwear from "./PowerbySoftwear";
import GamesGrid from "./GamesGrid";
import VideoModal from "../Common/VideoModal";
import { useLocation,useNavigate } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import { USER_ORDER_STATUS_URL,PACKAGES } from "../../components/Shared/constant";
import { useSelector } from "react-redux";
// import SuccessFullIcon from "../../../assets/img/sucessfullyicon.gif";
import SuccessFullIcon from "../../assets/img/sucessfullyicon.gif";
import { Link } from "react-router-dom";
import sound from "../../assets/audio/apple_pay_sound.wav";
//Calling WOWjs
import WOW from 'wowjs';

const Home = (props) => {
    const { accessToken } = useSelector((state) => state.allReducers);

    const [homepagepopup, setHomePagePopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [customerDetail, setCustomerDetail] = useState({});
    const [cardDetails, setCardDetails] = useState({});

    // https://scg.appristine.in/home?check_id=eda9fe40-5bb5-11ef-9d8c-b3d6ed0f6f41&number=10037&description=24c0075d-340b-4cdb-a461-f460e4dfe3b9&status=pending&paymentToken=pay_tok_SPECIMEN-0.29223734315000227&amount=2
    // https://scg.appristine.in/home?action=CryptoPaymentStatus&order=7eac66c6-9cf7-40b2-8b04-a036e3915d91
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location?.search);
    const action = searchParams.get('action');  // Should correctly get 'CryptoPaymentStatus'
    const order_Id = searchParams.get('order');    // Should correctly get '12345'

    const check_id = searchParams.get('check_id')
    const description_as_order_id = searchParams.get('description') // order_id
    
  
    useEffect(() => {
        if (action === 'CryptoPaymentStatus' && order_Id) {
            OrderStatusApiCall(order_Id);
        } else if (check_id &&  description_as_order_id) {
            OrderStatusApiCall(description_as_order_id);
        }
    }, [action, order_Id, check_id, description_as_order_id]);


    const OrderStatusApiCall = (order_Id) => {
        window.axios.get(`${USER_ORDER_STATUS_URL}/${order_Id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        })
        .then(function (result) {
            if (result?.data?.status === true) {
                setIsSuccess(true);
                setCustomerDetail(result.data);
                setCardDetails(result.data.transaction_id);
            } else if(result?.data?.status === null) {
                if(action === 'CryptoPaymentStatus'){
                    setPopupMessage('Your transaction is currently being processed. If you\'ve already sent the funds, don\'t worry you\'ll receive an email confirmation once we receive them. Please note that crypto transactions may take some time to confirm on the blockchain. If you have any questions or need more information, please contact our support team.');
                }else{
                    setPopupMessage("Your bank transfer is currently being processed. If you've already initiated the transfer, don't worry you'll receive an email confirmation as soon as we receive the funds. Please note that bank transfers typically take 1-2(maximum) business days to complete. If you have any questions or need more information, please contact our support team.") 
                 }
                setIsSuccess(false);
            }else{
                setPopupMessage("Your transaction was not successful. If you've already sent the funds, please contact our support team for assistance. If you need more information or have any questions, feel free to reach out to us.");
                setIsSuccess(false);
            }
            setHomePagePopup(true);
        })  
        .catch(function (error) {
            console.error("API call failed", error);
        });
    };

    useEffect(() => {
        if (homepagepopup && isSuccess) {
            const audio = new Audio(sound);
            audio.play().catch((error) => console.error("Audio play failed", error));
        }
    }, [homepagepopup, isSuccess]);

    const { setLocationGet, setLoginSigupUp, setSignUp } = props;
    const LocationUrl = useLocation();
    const [ videoModal, setVideoModal ] = useState({
        open: false,
        url: "",
    });
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    useEffect(() => {
        if(LocationUrl.pathname) {
            setLocationGet(LocationUrl.pathname);
        }
    }, [ LocationUrl ]);

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, []);

    return(<React.Fragment>
        {width>480 ?(
            <BannerSection />
        ):(
            <PowerbySoftwear state={true} setSignUp={setSignUp} setLoginSigupUp={setLoginSigupUp} />
        )}
        <GamesGrid setVideoModal={setVideoModal} videoModal={videoModal} />
        {width>480 ?(
            <PowerbySoftwear width={width} setSignUp={setSignUp} setLoginSigupUp={setLoginSigupUp} />
        ):(
            <PowerbySoftwear state={"FOOTER"} setSignUp={setSignUp} setLoginSigupUp={setLoginSigupUp} />
        )}

        {/* video modal */}
        {videoModal.open && (<VideoModal videoModal={videoModal} setVideoModal={setVideoModal} />)}
            {homepagepopup && (
                isSuccess ? (
                    <div className="successModalwraps" id="successPopup" >
                        <div className="successpymentwrapper">
                            <div className="succesiocn">
                                <img src={SuccessFullIcon} alt="correct icon" />
                            </div>
                            <h4>Thank You!</h4>
                            <p>We received your payment of <b>${(customerDetail?.total_amount || customerDetail?.amount) / 100}</b> <br />
                            We will send a confirmation email shortly to: <b>{customerDetail?.email}</b></p>
                            <div className="tarnsitionId">
                                <span>Transaction id</span>: {cardDetails}
                            </div>
                            <div className="successpymentbutns">
                                <Link className="btn cancelbtn" to={PACKAGES}>Sweepscoins points</Link>
                                <Button className="savebtn" onClick={() => { setHomePagePopup(false); navigate("/"); }}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="modal_close_wrapper">
            <h4>
                {popupMessage === 'Your transaction was not successful. If you\'ve already sent the funds, please contact our support team for assistance. If you need more information or have any questions, feel free to reach out to us.'
                    ? 'Your transaction was canceled.'
                    : 'Your transaction is currently being processed.'}
            </h4>
            <p>{popupMessage}</p>
            <Button onClick={() => { setHomePagePopup(false); navigate("/"); }}>Close</Button>
        </div>

                )
            )}

            {(homepagepopup || isSuccess) && (<div className="ModalBackground"></div>)}
    </React.Fragment>);
}

export default Home;