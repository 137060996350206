import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY_FACEBOOK,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_FACEBOOK,
  projectId: process.env.REACT_APP_PROJECT_ID_FACEBOOK,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_FACEBOOK,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_FACEBOOK,
  appId: process.env.REACT_APP_APP_ID_FACEBOOK,
  measurementId:process.env.REACT_APP_MEASUREMENT_ID_FACEBOOK
};
// Initialize Firebase
const app = initializeApp(firebaseConfig, 'SECONDARY_APP');
const authfb = getAuth(app);
const providerfb = new GoogleAuthProvider();
providerfb.setCustomParameters({
    prompt: "select_account",
  });
export {authfb,providerfb};

