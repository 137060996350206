/* eslint-disable */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HOME_URL, CONTACT } from './constant';
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import CommonDropdown from "../Common/CommonDropdown";

const ResponsiveSidebar = (props) => {
    const { accessToken, is_login, profiledata } = useSelector((state) => state.allReducers);
    const { setLoginSigupUp, setSignUp } = props;
    const LocationUrl = useLocation();
    const [ sidebarDropdown, setSidebarDropdown ] = useState(false);
    const [ Dropdwon, setDropdwon ] = useState(false);

    const closeRespSidebar = () => {
        var element = document.getElementById("responsiveSidebarMain");
        element.classList.remove("active");
        setSidebarDropdown(false);
    };

    return(<div className="responsiveSidebar" id="responsiveSidebarMain">
        <div className="togglebuttonrespo" onClick={() => closeRespSidebar()}>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div className="headerLinks">
            <ul>
                {(accessToken === "" && is_login === "no") && (<>
                    <li>
                        <a onClick={() => {
                            setLoginSigupUp(true);
                            closeRespSidebar();
                        }}>
                        Login
                        </a>
                    </li>
                    <li>
                        <a onClick={() => {
                            setSignUp(true);
                            closeRespSidebar();
                        }}>
                        Sign Up
                        </a>
                    </li>
                </>)}
                <li className={LocationUrl.pathname === HOME_URL ? "active" : ""} onClick={() => closeRespSidebar()}>
                    <Link to={HOME_URL}>Home</Link>
                </li>
                {/* <li>
                    <a className={sidebarDropdown ? "dropdwonsidebarMenua true" : "dropdwonsidebarMenua"} onClick={() => setSidebarDropdown(!sidebarDropdown)}>
                        Games
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2828 4.89817"><path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z"></path></svg>
                    </a>
                    {sidebarDropdown && (<ul className="dropdwonsidebar">
                        <li><Link to={KIPON} onClick={() => closeRespSidebar()}>Kipon</Link></li>
                        <li><Link to={ROBOTO_TRIX} onClick={() => closeRespSidebar()}>Robotrix</Link></li>
                        <li><Link to={TREASURE_BOX} onClick={() => closeRespSidebar()}>Treasure Box</Link></li>
                    </ul>)}
                </li> */}
                {/* <li className={LocationUrl.pathname === ABOUT_URL ? "active" : ""} onClick={() => closeRespSidebar()}>
                    <Link to={ABOUT_URL} >About</Link>
                </li> */}
                <li className={LocationUrl.pathname === CONTACT ? "active" : ""} onClick={() => closeRespSidebar()}>
                    <Link to={CONTACT} >Contact</Link>
                </li>
                {(accessToken !== "" && is_login !== "no") && (<div className="loginbtnbx login">
                <li >
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <h1 className="accountlogin" onClick={() => setDropdwon(!Dropdwon)}>
                                {(profiledata?.first_name !== "" && profiledata?.last_name !== "") ? profiledata?.first_name + " " + profiledata?.last_name : profiledata?.email?.split("@")[0]} 
                            </h1>
                                <svg className="dropdousericon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><g><g><polygon points="13,20.4 0,7.4 1.8,5.6 13,16.8 24.2,5.6 26,7.4   "></polygon></g></g></svg>
                        </Dropdown.Toggle>
                        <CommonDropdown setDropdwon={setDropdwon} accessToken={accessToken} />
                    </Dropdown>
                </li>
                </div>)}
            </ul>
        </div>
    </div>)
}

export default ResponsiveSidebar;