/* eslint-disable */
import React from "react";
import FooteImage from "../../../assets/img/sweepcoinscash-01.webp";
import {
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  PROMOTIONAL_RULES,
  CONTACT,
  HOME_URL,
} from "../../Shared/constant";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const Location = useLocation();

  const ScrolltoGame = () => {
    const GocontactForm = document.getElementById("root");
    GocontactForm?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <React.Fragment>
      {Location?.pathname !== "/packages" && (
        <div className="powerbysections powerbysectionsfooter">
          <Link
            to={HOME_URL}
            onClick={() => ScrolltoGame()}
            className="sweepscoinbtn"
          >
            SWEEPS COINS GAMES
          </Link>
          <h3>Purchase Products. Enter Sweeps. Be the Next WINNER!</h3>
        </div>
      )}
      <footer className="footer_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footwrapper">
                <div className="col-md-4">
                  <div className="contactlinks">
                    <h4>Contact</h4>
                    <ul>
                      <li>
                        <a href="mailto:support@sweepscoins.games">
                          support@sweepscoins.games
                        </a>
                      </li>
                      <li hidden>
                        Tel: <a href="tel:(888) 926-4679">(888) 9-COINS-9</a>
                      </li>
                      <li hidden>
                        Tel: <a href="tel:(888) 926-4679">(888) 926-4679</a>
                      </li>
                      <li className="d-flex flex-column justify-content-center align-items-center">
                       <div>625 Kenmoor Ave,</div> 
                       <div> SE Suite 210 </div>
                       <div>Grand Rapids, MI. 49546</div>
                       
                       
                        
                      </li>
                      <li hidden>
                        <a
                          href="https://www.sweepscoins.games/_files/ugd/1e9d5f_3b8fe763fea14e7db8b05d653436d542.pdf"
                          target="_blank"
                          className="entrylink"
                        >
                          CLICK HERE FOR A FREE ENTRY
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-6 footprofilecol">
                  <div className="contactlinks">
                    <h4>Policy Info</h4>
                    <ul>
                      <li>
                        <Link to={CONTACT}>Contact</Link>
                      </li>
                      <li>
                        <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to={PROMOTIONAL_RULES}>Promotional Rules</Link>
                      </li>
                      <li>
                        <a href="javscript:void(0)">Responsible Game Play</a>
                      </li>
                      <li>
                        <Link to={TERMS_CONDITIONS}>Terms & Conditions</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-md-3 col-sm-6 col-xs-6 footsocialcol">
                                    <div className="contactlinks">
                                        <h4>Social</h4>
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com" target="__blank">Facebook</a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com" target="__blank">Instagram</a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com" target="__blank">Youtube</a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com" target="__blank">Twitter</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                <div className="col-md-4">
                  <div className="contadetails">
                    <h4 hidden>Enter Sweepstakes Here</h4>
                    <img src={FooteImage} alt="Sweeps Coins Game" />
                  </div>
                </div>
              </div>
              <div className="copyright">
                &copy; {new Date().getFullYear()} Sweeps Coins Games. All Rights
                Reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
