/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";

const GamesGrid = (props) => {
    const { videoModal, setVideoModal } = props;
    const { products } = useSelector((state) => state.allReducers);
    const UrlRgx = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

    // open video modal
    const OpenVideoModal = (video) => {
        if(UrlRgx.test(video.video_url)) {
            setVideoModal({
                ...videoModal,
                open: !videoModal.open,
                url: video.video_url,
            });
        }
    };

    return(<section className="GamesGridwrapper">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="gamesgridset">
                        <div className="row">
                            {products && products?.map((game, index) => {
                                return(<div className="col-md-3 col-xs-6" key={index}>
                                    <div className="gamelistwraps" onClick={() => OpenVideoModal(game)}>
                                        <img src={game?.product_img_path} alt={game?.name} />
                                        <h4>{game?.name}</h4>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default GamesGrid;