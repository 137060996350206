/* eslint-disable */
import React from "react";
import SCIcons from "../../assets/img/sweepcoinscash-01.webp";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';


const PowerbySoftwear = (props) => {
    const { setSignUp, setLoginSigupUp } = props;
    const { accessToken, is_login } = useSelector((state) => state.allReducers);

    const AboutUsContent = () =>{
        return(
            <React.Fragment>
                <h5 className="wow fadeIn center" >About Sweeps Coins Games</h5>
                <h1 className="wow fadeIn center" >Powered by Nationwide Software</h1>
                <p className="wow fadeIn center" >At Sweeps Coins we're committed to offering you the best Play-From-Home systems at your convenience, for the grand possibility of you walking away a WINNER!!</p>
                
                {(accessToken === "" && is_login === "no") ? (<div className="btngroupswrapper">
                    <button type="button" className="btn signcmnbtn wow fadeIn center" onClick={() => setSignUp(true)}>Sign Up</button>
                    <span>OR</span>
                    <button type="button" className="btn signcmnbtn wow fadeIn center" onClick={() => setLoginSigupUp(true)}>Log In</button>
                </div>) : (<div className="btngroupswrapper" style={{ margin: "35px auto 35px" }}></div>)}    
            </React.Fragment>
        )
    }
    const IframeVideo = () =>{
        return(
            <iframe width="962" height="541" src="https://sweepscoinsgames.s3.us-east-2.amazonaws.com/The+Sweeps+Coins+Process.mp4" title="Orion Star Sweepstakes Games" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        )
    }
    const SweepCoinContent = () =>{
        return(
            <React.Fragment>
                <img src={SCIcons} className="imageONvideo" alt="image on video" />
                <h4>Online Sweepstakes Games</h4>
            </React.Fragment>
        )
    }
    if(props?.state == "FOOTER"){
        return(
            <section className="powerbysections">
                <div className="container container_header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="gamesonlinewrps wow fadeIn center" data-wow-duration="1s" data-wow-delay="1s">
                            {SweepCoinContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    if(props?.state){
        return(
            <section className="powerbysections">
                <div className="container container_header">
                    <div className="row">
                        <div className="col-md-12">
                            {AboutUsContent()}
                            <div className="gamesonlinewrps wow fadeIn center" data-wow-duration="1s" data-wow-delay="1s">
                                {!props?.state&&(
                                    <React.Fragment>
                                        <img src={SCIcons} className="imageONvideo" alt="image on video" />
                                        <h4>Online Sweepstakes Games</h4>
                                    </React.Fragment>
                                )}
                                {IframeVideo()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    // wow fadeIn center
    return(<section className="powerbysections">
        <div className="container container_header">
            <div className="row">
                <div className="col-md-12">
                        {AboutUsContent()}
                    <div className="gamesonlinewrps wow fadeIn center" data-wow-duration="1s" data-wow-delay="1s">
                        {IframeVideo()}
                        {SweepCoinContent()}
                    </div>
                </div>
            </div>
        </div>
    </section>);
}
PowerbySoftwear.propTypes = {
    state: PropTypes.any
}
PowerbySoftwear.defaultProps = {
    state: false
}
export default PowerbySoftwear;