import React from "react";

const TermsConditions = () => {
    return(<section className="privacypage">
        <div className="main-header">
            <div className="container pravcypolicycontainer">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Terms & Conditions</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="comnpages">
            <div className="container pravcypolicycontainer">
                <div className="row">
                    <div className="col-md-12">
                        <p>VERSION 3<br />
                        LAST UPDATED: 04/15/2024
                        <br /><br />
                        SWEEPS COINS WELCOMES YOU.  WE ASK THAT YOU READ THE FOLLOWING TERMS & CONDITIONS, WHICH CONSTITUTE A BINDING CONTRACT THAT COVERS YOUR USE OF THIS SITE.  BY ACCESSING, VIEWING, OR USING THIS SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE WITH THESE TERMS.  IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS, PLEASE DO NOT USE THIS SITE.
                        </p>
                        <p>
                            SECTION 1 – OVERVIEW
                            <br /><br />
                            1.1  This website is operated by Sweeps Coins.  Throughout the site, the terms “we”, “us” and “our” refer to Sweeps Coins.
                            <br /><br />
                            1.2  Any new features or tools which are added to the current website shall also be subject to the Terms & Conditions.  You can review the most current version of the Terms & Conditions at any time on this page.  We reserve the right to update, change or replace any part of these Terms & Conditions by posting updates and/or changes to our website.  It is your responsibility to check this page periodically for changes.  Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                            <br /><br />
                            1.3  By agreeing to these Terms & Conditions, you represent that you are at least the age of majority in your state or province of residence.
                            <br /> <br />
                            1.4  The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                        </p>
                        <p>
                            SECTION 2 – USE OF SITE
                            <br /><br />
                            2.1  Sweeps Coins is an authorized promotor/marketer for online retailers who sell various products.  In addition, we are a licensed distributor of various computer/mobile entertainment games (“games”) which are created and operated by third parties.  We use this website to run promotions for our affiliate retailers by providing sweepstakes entries into our fun computer/mobile entertainment games.  Entries can be obtained by making purchases from our digital retailers or via free-entry.  Entries can then be used to play the games.  Winnings from game play can be redeemed for cash prizes.  In order to participate and use the website, an individual must first open a membership with Sweeps Coins, which can be done by following the instructions on the website.  Please note, we cannot guarantee instantaneous upload of play credits onto your chosen platforms.  You should expect some delay between when you submit your entries and when your chosen games/platforms become available to you for play.  PLEASE NOTE, THERE ARE NO GAMES PLAYED ON THIS WEBSITE.  AS A LICENSE DISTRIBUTOR OF THIRD-PARTY GAMING SOFTWARE, WE ASSIST IN THE CREATION OF AND ACCESS TO YOUR ACCOUNT WITH THE GAME PROVIDER(S).
                            <br /><br />
                            2.2  Sweeps Coins offers a free entry/no purchase necessary option as part of its promotions.  We have no control over any of the games and we make no representations about the legality of the games in any particular jurisdiction.  The specific rules governing any promotions we run can be found on our website (official sweepstakes rules).  These Terms & Conditions apply to all our promotions and should be read in conjunction with our official sweepstakes rules.  In the event of a conflict, the specific sweepstakes rules will control.
                            <br /><br />
                            2.3  In addition, Sweeps Coins conducts a monthly drawing to award free products from our affiliate retailers and sweepstakes entries to its active members.  Each month, all active member accounts are entered into a randomized drawing.  The members whose account numbers are drawn will be awarded free products and/or entries.  Winners will receive notice of their prize(s) and any entries will automatically be credited to their accounts.
                            <br /><br />
                            2.4  Internet gaming may not be legal in some jurisdictions.  You understand and accept that we are unable to provide you with any legal advice or assistance in respect to your use of this site.  We make no representations whatsoever regarding the legality of the use of the games we distribute in your jurisdiction.  If you choose to use our website and redeem prizes, you are solely responsible for ensuring that, by playing these games, you are not violating any applicable laws, regulations, or orders in your jurisdiction.
                            <br /><br />
                            2.5  By using this site, you hereby warrant and attest to the following:
                                 <ul className="list-style-lower-alpha">
                                    <li>
                                        YOU ARE AT LEAST 18 YEARS OF AGE OR OLDER
                                    </li>
                                    <li>
                                        You are at least the majority age in your jurisdiction;
                                    </li>

                                    <li>
                                        You reside in a jurisdiction that is not excluded by our sweepstake rules;
                                    </li>

                                    <li>
                                        Your participation in the sponsored/promoted games is strictly for your own personal entertainment;
                                    </li>

                                    <li>
                                        Your participation in the sponsored/promoted games is not on behalf of anyone other than yourself;
                                    </li>

                                    <li>
                                        You will not and have not applied for more than one account on our site;
                                    </li>

                                    <li>
                                        You will not and have not used a shared account on our site, and you are the only person with access to your account;
                                    </li>

                                    <li>
                                        The information you provide us in opening an account is accurate and any payment information you provide is your own and not that of another person (PLEASE NOTE, WE WILL ONLY PAY REDEMPTIONS USING THE PAYMENT/ACCOUNT INFORMATION YOU PROVIDE, AND WE WILL NOT BE RESPONSIBLE IF YOU PROVIDE US WITH INACURATE PAYMENT/ACCOUNT INFORMATION);
                                    </li>

                                    <li>
                                        You are not engaging in any illegal activity through the use of our site and you are not making any redemption on our site with illegally obtain credits or credits which were purchased with illegally obtained funds; and
                                    </li>

                                    <li>
                                        You are not using a VPN or any other means to conceal your IP address and/or your physical location.
                                    </li>
                                 </ul>
                            <br /><br />
                            <br /><br />
                            2.6  We reserve the right to refuse service to anyone for any reason at any time.  We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction.  We may exercise this right on a case-by-case basis.  We reserve the right to limit the quantities of any products or services that we offer.  All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us.  We reserve the right to discontinue any product at any time.  Any offer for any product or service made on this site is void where prohibited.
                            <br /><br />
                            2.7  ANY PURCHASES MADE IN THE STATES OF WASHINGTON AND/OR MICHIGAN SHALL BE VOID.  NO USE IS PERMITTED BY ANY RESIDENTS OF THE STATES OF WASHINGTON, MICHIGAN, AND ANY OTHER EXCLUDED JURISDICTIONS AS SPECIFIED BY OUR SWEEPSTAKES RULES.  BY USING AND/OR ACCESSING THIS SITE, YOU HEREBY WARRANT THAT YOU SHALL NOT BY ANY MEANS, MASK, DISGUISE OR CHANGE YOUR IP ADDRESS, AND YOU AGREE THAT ANY SUCH MASKING, DISGUISING OR CHANGING SHALL CONSTITUTE FORFEITURE OF YOUR RIGHTS TO PARTICIPATE IN THE USE OF OUR SITE AND ANY PURCHASES YOU THEREBY MAKE SHALL BE VOID.
                            <br /><br />
                            2.8  Any use of this site other than the uses outlined in these Terms & Conditions is prohibited.  By way of example, you should not use any features of this site that permit communications or postings to post, transmit, display, or otherwise communicate (i) any defamatory, threatening, obscene, harassing, or otherwise unlawful information; (ii) any advertisement, solicitation, spam, chain letter, or other similar type of information; (iii) any encouragement of illegal activity; (iv) unauthorized use or disclosure of private, personally identifiable information of others; or (v) any materials subject to trademark, copyright, or other laws protecting any materials or data of others in the absence of a valid license or other right to do so.
                            <br /><br />
                            2.9  SHOULD YOU VIOLATE THESE TERMS & CONDITIONS IN ANY WAY, WE RESERVE THE RIGHT IN OUR SOLE DISCRETION TO IMMEDIATELY SUSPEND AND/OR TERMINATE YOUR ACCESS TO THE SITE.
                            <br /><br />
                            2.10  PLEASE NOTE, WE DO NOT CONTROL THE GAMES YOU ACCESS ON THIRD PARTY PLATFORMS.  THEREFORE, ANY SOFTWARE MALFUNCTION ON SAID PLATFORMS VOIDS PLAY AND WE ARE NOT RESPONSIBLE FOR PAYMENT OF REDEMPTIONS WHEN SUCH A MALFUNCTION OCCURS. 
                            <br /><br />
                            2.11  This web application may have periods of routine maintenance or other “down time” when customers cannot gain access.  We are not responsible for any losses, real or perceived, or any inconveniences caused by such lapses in access.
                            <br /><br />
                            2.12  We may send you promotional emails and SMS from time to time, and you hereby agree to receive the same.
                            <br /><br />
                            2.13  We shall not be liable for any loss including loss resulting from uncontrolled system failure and errors.  In the event of such failures/errors, you forfeit any prizes.  We will take reasonable steps to resolve failures/errors immediately, and we reserve the right to request screen shots from you if you report a failure/error.  We take no responsibility whatsoever nor do we accept liability for IT failures caused by your equipment or your internet service provider.

                        </p>
                        <p>
                            SECTION 3 – CUSTOMER ACCOUNTS
                            <br /><br />
                            3.1  Each customer may only have one account.  Accounts may not be shared by multiple customers.  Customers may not transfer or trade entries between accounts.  Accounts must be owned by individual persons and cannot be owned by businesses.  IF YOU ATTEMPT TO CREATE MULTIPLE ACCOUNTS, WE WILL PERMANENTLY BLOCK YOUR ACCESS TO THIS SITE.
                            <br /><br />
                            3.2  You are responsible for ensuring that payment information and contact information linked to your account is up to date and accurate.  We shall have no responsibility to complete any transactions for which the provided account information is not up to date and accurate.
                            <br /><br />
                            3.3  You are responsible to protect your own login information and to protect your account from being accessed by any third parties.  You must not share your account login information with anyone and you must not allow any other person to access your account.
                            <br /><br />
                            3.4  We reserve the right in our sole discretion to close any customer account we deem to be compromised by some security breach, used in violation of these Terms & Conditions, or inactive due to lack of use.
                            <br /><br />
                            3.5  You may choose to close your own account at any time.  You may do so by contacting our customer service representatives.
                        </p>
                        <p>
                            SECTION 4 – REDEMPTION OF PRIZES
                            <br /><br />
                            4.1  We reserve the right to verify account details and the identity of any customer who claims the redemption of any prize.  Prizes will not be distributed until we complete our own verification process.
                            <br /><br />
                            4.2  We reserve the right to charge fees for the cost of processing prize redemption.
                            <br /><br />
                            4.3  Prizes redeemed in the state of Florida and New York shall not exceed $5,000 for any one play.  We reserve the right to limit prizes redeemed in any jurisdiction to $10,000 per day.
                            <br /><br />
                            4.4  Any prizes redeemed for cash will be paid in US Dollars and no other currency.
                            <br /><br />
                            4.5  Cash prizes may take 10 or more business days to process.  You hereby acknowledge and accept that there may be delay in processing the redemption of cash prizes which are out of our control, such as banking clearance issues, security and fraud checks, AML compliance requirements, etc.
                            <br /><br />
                            4.6  You hereby authorize us to make cash payment for redemptions to the same financial institution and account you entered when you created your customer account.  If you would like to receive redemption proceeds in a different account, you will need to change the settings on your customer account.
                            <br /><br />
                            4.7  Redemption requests can be made at Sweepscoins.cash.  You can specify how many credits/the amount of winnings you would like to redeem from any particular gaming platform.  If you request redemption of more credits/winnings than is currently available in any of your platform accounts, we will issue payment on the entire amount in the subject account and we will send you a message stating how much you requested and how much we were actually able to process/redeem for you.
                            <br /><br />
                            4.8  We reserve the right to issue payment in any form we deem fit, including but not limited to ACH, written check, cash, or any other form of payment in US dollars.
                        </p>
                        <p>
                            SECTION 5 – SITE CONTENTS AND OWNERSHIP
                            <br /><br />
                            The information contained on this site, including all images, designs, photographs, writings, graphs, data, and other materials (Materials) are solely the property of us and are protected by copyrights, trademarks, trade secrets, or other proprietary rights.  Permission is granted to display, copy, distribute, download, and print portions of this site solely for the purposes of using this site for the authorized uses described in these Terms & Conditions.  You must retain all copyright and other proprietary notices on all copies of the Contents.  You shall comply with all copyright laws worldwide in your use of this website and prevent unauthorized copying of the Contents.  Except as provided in this Notice, we do not grant you any express or implied right in or under any patents, trademarks, copyrights, or trade secret information.
                        </p>
                        <p>
                            SECTION 6 – DISCLAIMER OF WARRANTY
                            <br /><br />
                            
                            6.1  You expressly agree that use of this website is at your sole risk.  Neither us, our affiliates, nor any of our officers, directors, employees, agents, third-party content providers, or licensors (collectively, “Providers”), or the like, warrant that this site will be uninterrupted or error-free; nor do we make any warranty as to the results that may be obtained from the use of this site, or as to the accuracy, completeness, reliability, security, or currency of the Materials.
                            <br /><br />
                            6.2  The Materials may contain errors, omissions, inaccuracies, or outdated information.  Further, we do not warrant reliability of any statement or other information displayed or distributed through the site.  We reserve the right, at our sole discretion, to correct any errors or omissions in any portion of the site.  We may make any other changes to this site, the Materials and the products, programs, services, or prices (if any) described in this site at any time without notice.
                            <br /><br />
                            6.3  THIS SITE AND THE INFORMATION, CONTENT, AND MATERIALS ON THIS SITE ARE PROVIDED ON AN “AS IS,” “WHERE IS,” AND “WHERE AVAILABLE” BASIS.  WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE, THE CONTENT, INFORMATION, OR THE MATERIALS ON THIS SITE.  TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND, WITH RESPECT TO ANY OF THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE OR ANY GOODS OR OTHER PRODUCTS OR SERVICES OFFERED, SOLD, OR DISPLAYED ON THIS SITE OR YOUR USE OF THIS SITE GENERALLY, INCLUDING WARRANTIES OF MERCHANTABILITY, ACCURACY OF INFORMATION, QUALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.  SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE EXTENT SUCH JURISDICTION’S LAW APPLIES TO THIS AGREEMENT.
                        </p>
                        <p>
                            SECTION 7 – LIMITATION OF LIABILITIES
                            <br /><br />
                            YOU AGREE THAT WE AND OUR AFFILIATES SHALL NOT BE LIABLE FOR ANY DAMAGE, LOSS, OR EXPENSE OF ANY KIND ARISING OUT OF OR RESULTING FROM YOUR POSSESSION OR USE OF THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE REGARDLESS OF WHETHER SUCH LIABILITY IS BASED IN TORT, CONTRACT, OR OTHERWISE.  IN NO EVENT, INCLUDING, WITHOUT LIMITATION, ANY NEGLIGENT ACT, SHALL WE OR ANY OF OUR AFFILIATES BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, LOSS OR CORRUPTION OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR INTERRUPTION OF BUSINESS), ARISING OUT OF OR IN ANY WAY RELATED TO THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE OR ANY OTHER PRODUCTS, SERVICES, OR INFORMATION OFFERED, SOLD, OR DISPLAYED ON THIS SITE, YOUR USE OF, OR INABILITY TO USE, THIS SITE GENERALLY, OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT, REGARDLESS OF WHETHER WE OR ANY OF OUR AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  BECAUSE SOME STATES DO NOT ALLOW THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                        </p>
                        <p>
                            SECTION 8 - ACCURACY OF BILLING AND ACCOUNT INFORMATION
                            <br /><br />
                            You agree to provide current, complete and accurate purchase and account information for all purchases made on our site.  You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
                        </p>
                        <p>
                            SECTION 9 – INDEMNIFICATION
                            <br /><br />
                            You agree to indemnify, defend, and hold harmless us, our affiliates, agents, employees, and licensors from and against any and all claims and expenses, including reasonable attorney fees, arising out of or related in any way to your use of the site, use of our software and/or products, violation of this Agreement, violation of any law or regulation, or violation of any proprietary or privacy right.
                        </p>
                        <p>
                            SECTION 10 – PRIVACY POLICY
                            <br /><br />
                            Your submission of personal information through the store is governed by our Privacy Policy, which can be viewed on the site.
                        </p>
                        <p>
                            SECTION 11 – LIMITATIONS ON CLAIMS
                            <br /><br />
                            Any cause of action you may have with respect to your use of this site must be commenced within one year after the claim or cause of action arises, or it is waived.
                        </p>
                        <p>
                            SECTION 12 – MINORS
                            <br /><br />
                            We do not knowingly collect, either online or offline, personal information from persons under the age of thirteen.  If you are under the age of majority in your jurisdiction, you may not use this website.
                        </p>
                        <p>
                            SECTION 13 – HYPERLINK DISCLAIMERS
                            <br /><br />
                            As a convenience to you, we may provide on this site links to websites operated by other entities (collectively the “Linked Sites”).  If you use any Linked Sites, you will leave this site.  If you decide to visit any Linked Site, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements.  Linked Sites, regardless of the linking form (e.g., hotlinks, hypertext links, IMG links) are not maintained, controlled, or otherwise governed by us.  The content, accuracy, opinions expressed, and other links provided by Linked Sites are not investigated, verified, monitored, or endorsed by us.  We do not endorse, make any representations regarding, or warrant any information, goods, and/or services appearing and/or offered on any Linked Site, other than linked information authored by us.  Links do not imply that we sponsor, endorse, are affiliated or associated with, or are legally authorized to use any trademark, trade name, logo, or copyright symbol displayed in or accessible through the links, or that any Linked Site is authorized to use any trademark, trade name, logo or copyright symbol of ours or any of our affiliates or subsidiaries.  Except for links to information authored by us, we are neither responsible for nor will we be liable under any theory based on (i) any Linked Site; (ii) any information and/or content found on any Linked Site; or (iii) any site(s) linked to or from any Linked Site.  If you decide to visit any Linked Sites and/or transact any business on them, you do so at your own risk.  We reserve the right to discontinue any Linked Site at any time without prior notice.  Please contact the webmasters of any Linked Sites concerning any information, goods, and/or services appearing on them.
                        </p>
                        <p>
                            SECTION 14 – CONTROLLING LAW, JURISDICTION, AND INTERNATIONAL USERS
                            <br /><br />
                            This Agreement is governed by and shall be construed in accordance with the laws of the State of Michigan, U.S.A., without reference to its conflict-of-law provisions.  We make no representation that the materials are appropriate or available for use outside the United States.  If you access this site from outside the United States, you will be responsible for compliance with all local laws.  You agree to comply with all laws and regulations applicable to your use of this site.  Subject to our Arbitration Agreement, you agree to submit to the personal and exclusive jurisdiction of the state or federal courts located within Kent County, Michigan for any disputes with us arising out of your use of this site.
                        </p>
                        <p>
                            SECTION 15 – ENTIRE AGREEMENT
                            <br /><br />
                            This Agreement constitutes the entire agreement between us and you with respect to this website, and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between you and us with respect to this website.  A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based on or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.  If for any reason a court of competent jurisdiction finds any provision of this Agreement or portion of it to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effectuate the intent of this Agreement, and the remainder of this Agreement shall continue in full force and effect.  No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.
                        </p>
                        <p>
                            SECTION 16 – ARBITRATION AGREEMENT
                            <br /><br />
                            In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms & Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties.  The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction.  In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms & Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees.  The parties agree to arbitrate all disputes and claims in regards to these Terms & Conditions or any disputes arising as a result of these Terms & Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms & Conditions.  The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator.  This arbitration provision shall survive the termination of these Terms & Conditions.
                        </p>
                        <p>
                            SECTION 17 - SEVERABILITY
                            <br /><br />
                            In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
                        </p>
                        <p>
                            SECTION 18 – CLASS ACTION WAIVER
                            <br /><br />
                            Any arbitration under these Terms & Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.  Further, unless both you and we agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.
                        </p>
                        <p>
                            SECTION 19 – NO THIRD-PARTY BENEFICIARIES
                            <br /><br />
                            Nothing in these Terms & Conditions shall create any third-party rights, nor shall anything in these Terms & Conditions bind us to any sort of covenant, agreement, or contract with a third party.
                        </p>
                        <p>
                            SECTION 20 – MODIFICATIONS TO AGREEMENT
                            <br /><br />
                            We may revise this Agreement at any time and you agree to be bound by the revised Agreement.  Any such modifications will become effective on the date they are first posted to this site.  It is your responsibility to return to this Agreement from time to time to review the most current Terms & Conditions.  We do not and will not assume any obligation to notify you of changes to this Agreement.
                        </p>
                        <p>
                            SECTION 21 – ELECTRONIC COMMUNICATIONS AND ELECTRONIC SIGNATURES
                            <br /><br />
                            You agree to be bound by any affirmation, assent, or agreement you transmit through this website, including but not limited to any consent you give to receive communications from us solely through electronic transmission.  You agree that when in the future you click on an “I agree,” “I consent,” or other similarly worded “button” or entry field with your touchscreen, mouse, keystroke, or otherwise, your agreement or consent will be legally binding and enforceable and shall be the legal equivalent of your handwritten signature.
                        </p>
                        <p>
                            SECTION 22 – FORCE MAJEURE
                            <br /><br />
                            Neither party to this agreement shall be liable or responsible to the other party for any failure or delay in fulfilling or performing any term of this agreement when such failure or delay is caused by events beyond the affected party’s reasonable control.  This includes, but is not limited to (1) acts of God; (2) flood, fire, earthquake, or explosion; (3) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot, or other civil unrest; (4) changes in the law or regulatory enforcement which do not allow for continued operations of either party; (5) actions, embargoes, or blockades in effect on or after the date of this agreement; and (6) national or regional emergency.  The party suffering a Force Majeure event shall give prompt notice to the other party, stating the period of time the occurrence is expected to continue, and shall use diligent efforts to end the failure or delay and ensure the effects of such Force Majeure event are minimized.  Section XI does not apply to obligations to make payments for services rendered and/or revenue collected prior to the Force Majeure event.
                        </p>
                        <p>
                            SECTION 23 – ASSIGNMENT AND DELEGATION
                            <br /><br />
                            You may not assign any rights or delegate any duties granted to you under this agreement.
                        </p>
                        <p>
                            SECTION 24– NON-WAIVER
                            <br /><br />
                            No waiver by any party of any of the provisions of this agreement shall be effective unless set forth in writing and signed by the waiving party.  No waiver by any party shall operate or be construed as a waiver regarding any failure, breach, or default not expressly identified by such written waiver, whether of a similar or different character, and whether occurring before or after that waiver.  No failure to exercise, or delay in exercising, or partial exercise of any right or remedy arising under this agreement shall operate or be construed as a waiver, nor shall it preclude any other or further exercise of the waiver or the exercise of any other right or remedy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default TermsConditions;