/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/css/media.css";
import { HOME_URL, GET_PROFILE_API_URL, PACKAGES, USER_DATA_DETECTION, CHECK_OUT_PACKAGE, GET_PRODUCT_API_URL, ALL_SESSION_LOGOUT_API_URL, MY_ACCOUNT, RESET_PASSWORD, PRIVACY_POLICY, TERMS_CONDITIONS, PROMOTIONAL_RULES, CONTACT, KIPON, ROBOTO_TRIX, TREASURE_BOX, FREE_CREDIT } from './components/Shared/constant';
import Header from "./components/Shared/Header";
import Home from './components/Home';
import Footer from './components/Shared/Footer';
import Account from "./components/Account";
import Contact from "./components/Contact";
import Checkout from './components/Checkout';
import SignUpSidebar from './components/Shared/SignUp';
import LoginSignupModal from './components/LoginSignupModal';
import Privacy from './components/Privacy';
import Userdatadeletion from './components/Userdatadeletion';
import TermsConditions from './components/TermsConditions';
import PromotionalRules from './components/PromotionalRules';
import ScrollToTop from './components/ScrollToTop';
// import Landing from './components/Landing';
// import About from './components/About';
import ResetPassword from './components/ResetPassword';
// import Kipon from './components/Games/kipon';
// import Robotrix from './components/Games/Robotrix';
// import Treasurebox from './components/Games/Treasurebox';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResponsiveSidebar from './components/Shared/ResponsiveSidebar';
import { Accordion, Image } from 'react-bootstrap';
import CorrectIcon from "./assets/img/check_mark.png";
import CloseNewIcon from "./assets/img/close_mark.png";
import { getUser, getAccessToken, checkLogin, getRegioLcTime, ClearReduxFlow, getGeoCoplyMessage, getLicenseCoplyMessage, getUniqueBrowserId, getGeoCoplyLocation, getSpoofingDetection,IsSiteIsBlockCheck, GetProductsIdWise, CallLogoutUser, getProductList } from './redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import Packages from './components/Packages';
import EighteenYearsOldModel from './components/Common/EighteenYearsOldModel';
import CommonTermsPrivacy from './components/Common/CommonTermsPrivacy';
import FacebookPixel from './components/Common/FacebookPixel';
import PackageCheckout from './components/Checkout/PackageCheckout';
import FreeCredit from './components/FreeCredit';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {
    const [ SignUp, setSignUp ] = useState(false);
    const [ LoginSigupUp, setLoginSigupUp ] = useState(false);
    const [ LocationGet, setLocationGet ] = useState("");
    const dispatch = useDispatch();
    const { accessToken, is_login, profiledata, EighteenPlusModel, StopOverCalling, accountUrl, ComplyErrorMessage, ReGeoLcTimeGet, LicenseErrorMsg, UniqueBrowserId, spoofingDetection,checkCacheNewz, geoComplyLocation, isSiteBlock } = useSelector((state) => state.allReducers);
    const [ AllclearData, setAllclearData ] = useState(false);
    const LocationUrl = window.location.href.split("/")[3];
    const [ checkUserIsBlock, setCheckUserIsBlock ] = useState({
        attempt: 0,
        Status: "",
    });
    const [ device, setdevice ] = useState({
        status: false,
        msg: "",
        retry: 0,
    });
    const [ boundary, setboundary ] = useState({
        status: false,
        msg: "",
        retry: 0,
    });
    const [ spoofing_detection, setspoofing_detection ] = useState({
        status: false,
        msg: "",
        retry: 0,
    });
    const [ dev_tool_simulation, setdev_tool_simulation ] = useState({
        status: false,
        msg: "",
        retry: 0,
    });
    const [ solus_dev_mode1, setsolus_dev_mode1 ] = useState({
        status: false,
        msg: "",
        retry: 0,
    });
    const [ EighteenModelState, setEighteenModelState ] = useState(false);
    const [ TermsPravacyState, setTermsPravacyState ] = useState({
        open: false,
        Url: "",
    });

    useEffect(() => {
        if(LocationUrl === "?action=privacy-policy") {
            setTermsPravacyState({...TermsPravacyState, open: !TermsPravacyState?.open, Url: "PRIVACY_POLICY"});
        };
        if(LocationUrl === "?action=terms-and-conditions") {
            setTermsPravacyState({...TermsPravacyState, open: !TermsPravacyState?.open, Url: "TERMS_CONDITIONS"});
        };
        localStorage.setItem("REACT_APP_BASE_URL", process.env.REACT_APP_BASE_URL)
    }, []);

    useEffect(() => {
        if(checkCacheNewz === undefined || checkCacheNewz === null) {
          dispatch(ClearReduxFlow());
        };
    }, [checkCacheNewz]);

    useEffect(() => {
        if(sessionStorage.getItem("SiteBlockChecking") === null || sessionStorage.getItem("SiteBlockChecking") === "yes") {
            setEighteenModelState(true);
        };
    }, [ LocationGet ]);

    // useEffect(() => {
    //     if(accessToken !== "") {
    //         window.axios.get(ALL_SESSION_LOGOUT_API_URL, {
    //             headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //             'Authorization': 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl9pZCI6ImQyOGVjOWVjLWZlMWQtNDMzNi05YmJiLTZlZDYwYWNjMTZkOCIsImV4cCI6MTc1NDA1NTAyNiwiaWF0IjoxNzIyNTE4NzI2fQ.b_EXUcdKVfwziV6Qf52Q2Z4aJJwJTxl5ehSHKxgaOso"
    //         }}).then(function (result) {
    //             if(result?.status === 200) {
                
    //             };
    //         }).catch(function (result) {
    //             if(result?.response?.status === 403) {
    //                 toast.error("The session expired due to logging in on another device.");
    //                 dispatch(CallLogoutUser());
    //                 localStorage.removeItem("access_tokens");
    //                 // window?.href("/");
    //                 // setTimeout(() =>  Navigate(HOME_URL), 200);

    //                 // window.open(HOME_URL, "");
    //                 // Redirect to a new URL
    //                 // window.location.href = HOME_URL;

    //             };
    //         });
    //     }
    // }, [ accessToken, LocationUrl, is_login, accountUrl ]);
  
    useEffect(()=>{
        if(spoofingDetection === null || spoofingDetection === "" || spoofingDetection === undefined){
            document.getElementById("spoofingDetectionModel").style.display = "none";
        }else{
            document.getElementById("spoofingDetectionModel").style.display = "flex";
        };
    },[spoofingDetection]);

    // Geocomply function all code
    useEffect(() => {
        if(accessToken !== "") {
            if(ReGeoLcTimeGet !== null && ReGeoLcTimeGet !== "") {
                callGeoTimeApi();
            }
        }
    }, [ ReGeoLcTimeGet, accessToken, StopOverCalling ]);

    // callback call
    const callGeoTimeApi = () => {
        var starttime = new Date().getTime();
        var second = ReGeoLcTimeGet;
        var minutes = second/60;
        var d = new Date();
        var t_min = d.getMinutes();
        var targettime = d.setMinutes(t_min + minutes);
        targettime = d.getTime();

        if(localStorage.getItem("starttime") === "" || localStorage.getItem("starttime") === null) {
            localStorage.setItem("starttime", starttime);
        };
        if(localStorage.getItem("targettime") === "" || localStorage.getItem("targettime") === null) {
            localStorage.setItem("targettime", targettime);
        };
        console.log(`%cTargettime: ${new Date(targettime)}`, "font-size: 15px;color: #2b074d; border: 2px solid green; padding: 6px 20px;");
        let delay = (localStorage.getItem("targettime") ? localStorage.getItem("targettime") : targettime) - (localStorage.getItem("starttime") ? localStorage.getItem("starttime") :starttime );
        
        if (delay > 0) {
            var Mytimeout = setTimeout(() => {
                if(localStorage.getItem("access_tokens") !== null) {
                    if(Date.now() >= localStorage.getItem("targettime")) {
                        GetGeoLicenceFunction();
                    }
                };
            }, delay);

            if(StopOverCalling === true) {
                clearTimeout(Mytimeout);
                delay = 0;
            };
        }
    }; 

    // document.getElementById("TryAgainGeoComplyt").onclick = function() {
    //     setTimeout( async () => {
    //         GetGeoLicenceFunction();
    //         document.getElementById("pageisLoading").style.display = "flex";
    //     }, 200);
    // };
    
    const GeoComplyHit = () => {
        setTimeout( async () => {
            GetGeoLicenceFunction();
            document.getElementById("pageisLoading").style.display = "flex";
        }, 200);
    };

    const GetGeoLicenceFunction = async () => {
        if(UniqueBrowserId !== "") {
            dispatch(getRegioLcTime(""));
            document.getElementById("pageisLoading").style.display = "flex";
            window.axios.get(`${process.env.REACT_APP_BASE_URL}user/geocomply/license/${UniqueBrowserId}`, {
                headers: {
                    'Content-Type': 'application/json',
                }}).then(function (result) {
                    if(result?.status === 200) {
                        GetGeoLocate(result?.data?.data?.license, "Login");
                        dispatch(getGeoCoplyMessage(""));
                    };
                }).catch(function (result) {
                    dispatch(getGeoCoplyMessage(""));
                    dispatch(getLicenseCoplyMessage(result?.response?.data?.error));
                    document.getElementById("pageisLoading").style.display = "none";
                    if(result?.data?.data?.error === "Geo-comply failed.") {
                        // AccessTokenCheckLogout();
                    };
                });
        }
    };

    // Geo location api
    const GetGeoLocate = (jsonData, url) => {
        var geoClient = window.GcHtml5.createClient();
        geoClient.setUserId(UniqueBrowserId);
        geoClient.setReason(url);
        geoClient.setLicense(jsonData);
        geoClient.events.on('hint', function(reason, data) {
        }).on('engine.success', function(text, xml) {
            LocationPermission(text);
            dispatch(getGeoCoplyLocation(text));
            // localStorage.setItem("GeoLocationToken", text);
        }).on('*.failed', function(code, message) {
            if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                    //e.g. update license and retry geolocating
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                }
            } else if (this.event === 'browser.failed') {
                //it's safe to be ignored, you can remove this block or add your own logic
            }
        });
        geoClient.request();
    };

    window.onload = function() {
        if(isSiteBlock === false) {
            if(checkUserIsBlock?.attempt !== 1 || checkUserIsBlock?.attempt !== 0) {
                setTimeout( async () => {
                    GetGeoLicenceFunction();
                    document.getElementById("pageisLoading").style.display = "flex";
                }, 200);
            };
        }
    };

    const windowsOpenNewTab = () => {
        var width = window.screen.width;
        var height = window.screen.height;

        // Define the window features as a string
        var features = `width=${width},height=${height},left=0,top=0,fullscreen=yes,toolbar=no,menubar=no,location=no,status=no`;
        window.open(window.location.href, "_blank", features);
    }

    // location Permissions api
    const LocationPermission = async (text) => {
        const jsonData = JSON.stringify({ 
            "id": UniqueBrowserId,
            "data": text
        });
        window.axios.post(`${process.env.REACT_APP_BASE_URL}user/geocomply/permission`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            dispatch(getLicenseCoplyMessage(""));
            document.getElementById("pageisLoading").style.display = "none";
            if(result?.status === 200) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
                if(result?.data?.data?.status === true) {
                    // success code
                    dispatch(getRegioLcTime(result?.data?.data?.re_geolocation));
                    localStorage.removeItem("starttime");
                    localStorage.removeItem("targettime");
                }
                dispatch(getSpoofingDetection(result?.data?.data?.spoofing_detection));
                if(result?.data?.data?.status === false) {
                    dispatch(getGeoCoplyMessage(result?.data?.data?.troubleshooter));
                    setCheckUserIsBlock({
                        ...checkUserIsBlock,
                        attempt: result?.data?.data?.attempt,
                        Status: result?.data?.data?.match,
                    });
                    if(result?.data?.data?.spoofing_detection != null){
                        // add spoofing detection popup
                        console.log("spoofing detection");
                    }
                }
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
        })  
        .catch(function (result) {
            document.getElementById("pageisLoading").style.display = "none";
            dispatch(getLicenseCoplyMessage(""));
            if(result?.response?.data?.error === "Geo-comply failed.") {
                GetGeoLicenceFunction();
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
        });
    };

    useEffect(() => {
        if(isSiteBlock !== false && isSiteBlock !== null) {
            if(accessToken !== "" && accessToken !== null){
                window.axios.get(GET_PROFILE_API_URL, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }}).then(function (result) {
                    if(result?.status === 200) {
                        dispatch(getUser(result?.data?.data));
                    };
                }).catch(function (result) {
                    if(result?.response?.status === 403) {
                        dispatch(CallLogoutUser());
                        localStorage.removeItem("access_tokens");
                    }
                });
            };

            //  get my product
            window.axios.get( `${GET_PRODUCT_API_URL}/5000/1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then(function (result) {
                dispatch(GetProductsIdWise(result.data.data));
                dispatch(getProductList(result.data.data));
            }).catch(function (result) {
                dispatch(GetProductsIdWise([]));
                dispatch(getProductList([]));
                if(result?.response?.status === 403) {
                    dispatch(CallLogoutUser());
                    localStorage.removeItem("access_tokens");
                };
            });

            if(checkCacheNewz === undefined || checkCacheNewz === null) {
                dispatch(CallLogoutUser());
            };
        }
    }, [ isSiteBlock ]);


    // if accessToken expire then page will logout and redirect to home page 
    const AccessTokenCheckLogout = () => {
        setTimeout(() =>  Navigate(HOME_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        dispatch(getUser(""));
    };

    // input field space issue 
    document.getElementById("input")?.addEventListener('keydown', function (e) {
        if (this.value.length === 0 && e.which === 32) e.preventDefault();
    });

    const ClickOutSideCloseSidebar = () => {
        setTimeout(() => {
            document.getElementById("signupflag")?.classList?.remove("active");
            setSignUp(false);
            setAllclearData(true);
        }, 200);
    };

    useEffect(() => {
        if(ComplyErrorMessage !== undefined || ComplyErrorMessage !== null) {
            ComplyErrorMessage && ComplyErrorMessage.forEach((elm) => {
                if(elm?.attrib?.rule === "device" ) {
                    setdevice({...device, status: true, msg: elm.msg, retry: elm?.attrib?.retry});
                } else if(elm?.attrib?.rule === "boundary") {
                    setboundary({...boundary, status: true,msg: elm.msg, retry: elm?.attrib?.retry});
                } else if(elm?.attrib?.rule === "spoofing_detection") {
                    setspoofing_detection({...spoofing_detection, status: true,msg: elm.msg, retry: elm?.attrib?.retry});
                } else if(elm?.attrib?.rule === "dev_tool_simulation" ) {
                    setdev_tool_simulation({...dev_tool_simulation, status: true,msg: elm.msg, retry: elm?.attrib?.retry});
                } else if(elm?.attrib?.rule === "solus_dev_mode") {
                    setsolus_dev_mode1({...solus_dev_mode1, status: true,msg: elm.msg, retry: elm?.attrib?.retry});
                }
            })
        };
      }, [ComplyErrorMessage]);
      
      const BlockMessageShowAsPer = () => {
        return(<div className="Error_message_wraps">
        <h4>Could Not Locate You.</h4>
        <small>Please fix these issues then try again.</small>
        {(checkUserIsBlock?.attempt !== 1 && checkUserIsBlock?.Status !== "") && (<div className='AttepsshowError'>
          {checkUserIsBlock?.attempt === 2 && "Attention: Only 4 Verification Attempts Left!"}
          {checkUserIsBlock?.attempt === 3 && "Attention: Only 3 Verification Attempts Left!"}
          {checkUserIsBlock?.attempt === 4 && "Attention: Only 2 Verification Attempts Left!"}
          {checkUserIsBlock?.attempt === 5 && "Attention: Only 1 Verification Attempt Left!"}
          {checkUserIsBlock?.Status === "block" && "Maximum failed attempt. Please retry after 30 minutes."}
        </div>)}
        <Accordion>
          <div className='row'>
              <div className='col-md-6'>
                  <h3>Customer support</h3>
                  <div className='collpseheader'>
                    {device?.status === false ? (<Image src={CorrectIcon} alt='success' />) : (<Image src={CloseNewIcon} alt='error' />)}
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>How to fix this?</Accordion.Header>
                      <Accordion.Body>
                      There is an issue with your account. Please contact customer support for more information.
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
              </div>
              <div className='col-md-6'>
                  <h3>You may be out of state.</h3>
                  <div className='collpseheader'>
                    {boundary?.status === false ? (<Image src={CorrectIcon} alt='success' />) : (<Image src={CloseNewIcon} alt='error' />)}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How to fix this?</Accordion.Header>
                      <Accordion.Body>
                      Your device's location data indicates you are not in a permitted area. Please make sure that you are within a permitted area, then try again.
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
              </div>
          </div>
          <div className='row'>
              <div className='col-md-6'>
                <h3>VPN Detected.</h3>
                <div className='collpseheader'>
                  {spoofing_detection?.status === false ? (<Image src={CorrectIcon} alt='success' />) : (<Image src={CloseNewIcon} alt='error' />)}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>How to fix this?</Accordion.Header>
                    <Accordion.Body>
                    For Security purposes, you are required to turn off browser extensions. Please disable, then try again.
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </div>
              <div className='col-md-6'>
                <h3>Turn browser developer mode off</h3>
                <div className='collpseheader'>
                  {(dev_tool_simulation?.status === false || solus_dev_mode1?.status === false) ? (<Image src={CorrectIcon} alt='success' />) : (<Image src={CloseNewIcon} alt='error' />)}
                  <Accordion.Item eventKey="3">  
                    <Accordion.Header>How to fix this?</Accordion.Header>
                    <Accordion.Body>
                    For Security purposes, you are required to turn off developer mode in the browser. Please disable, then try again.
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </div>
          </div>
          {/* <div className='row'>
            <div className='col-md-6'>
              <h3>Turn browser developer mode off</h3>
              <div className='collpseheader'>
                  {solus_dev_mode1?.status === false ? (<Image src={CorrectIcon} alt='success' />) : (<Image src={CloseNewIcon} alt='error' />)}
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How to fix this?</Accordion.Header>
                  <Accordion.Body>
                  For Security purposes, you are required to turn off developer mode in the browser. Please disable, then try again.
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            </div> 
          </div>  */}
        </Accordion>
        {/* <button type="button" onClick={() => windowsOpenNewTab()} style={{ marginTop: "30px" }}>Try again</button> */}
        <button type="button" onClick={() => GeoComplyHit()} style={{ marginTop: "30px" }}>Try again</button>
    </div>)
    };

    const ButtonShowAfterTime = () => {
        // setTimeout(() => {
        //   return(<button type="button" id="TryAgainGeoComplyt" style={{ marginTop: "30px" }}>Try again</button>)
        // }, 1800000);
            return(<button type="button" onClick={() => GeoComplyHit()} style={{ marginTop: "30px" }}>Try again</button>)
    };

    return (<React.Fragment>
        {isSiteBlock === true ? (<div className="wrapper">
                <BrowserRouter>
                {LocationGet.split("/")[1] !== "reset-password" && <Header setSignUp={setSignUp} setLoginSigupUp={setLoginSigupUp} setLocationGet={setLocationGet} />}
                    <ScrollToTop />
                    <Routes>
                        <Route path="*" element={<Navigate replace to={HOME_URL}/>} />
                        {/* <Route path={HOME_LANDING_URL} element={<Landing />} /> */}
                        <Route path={HOME_URL} element={<Home setLoginSigupUp={setLoginSigupUp} setSignUp={setSignUp} setLocationGet={setLocationGet} />} />
                        {/* <Route path={ABOUT_URL} element={<About />} /> */}
                        <Route path={MY_ACCOUNT} element={(accessToken !== "" && is_login !== "no") ? <Account /> : <Navigate replace to={HOME_URL}/>} />
                        <Route path={PRIVACY_POLICY} element={<Privacy />} />
                        <Route path={TERMS_CONDITIONS} element={<TermsConditions />} />
                        <Route path={PROMOTIONAL_RULES} element={<PromotionalRules />} />
                        <Route path={CHECK_OUT_PACKAGE} element={<Checkout setLoginSigupUp={setLoginSigupUp} />} />
                        <Route path={`${CHECK_OUT_PACKAGE}/:order_id`} element={<PackageCheckout setLoginSigupUp={setLoginSigupUp} />} />
                        <Route path={`${CHECK_OUT_PACKAGE}/:order_id/:payment_id`} element={<PackageCheckout setLoginSigupUp={setLoginSigupUp} />} />
                        <Route path={`${FREE_CREDIT}/:free_credit`} element={<FreeCredit setLoginSigupUp={setLoginSigupUp} />} />
                        <Route path={CONTACT} element={<Contact />} />
                        {/* <Route path={KIPON} element={<Kipon />} />  */}
                        {/* <Route path={ROBOTO_TRIX} element={<Robotrix />} /> */}
                        {/* <Route path={TREASURE_BOX} element={<Treasurebox />} /> */}
                        <Route path={`${RESET_PASSWORD}/:roomId`} element={<ResetPassword />} />
                        <Route path={PACKAGES} element={<Packages />} />
                        <Route path={USER_DATA_DETECTION} element={<Userdatadeletion />} />
                    </Routes>
                    {LocationGet.split("/")[1] !== "reset-password" && <Footer />}
                    <SignUpSidebar SignUp={SignUp} AllclearData={AllclearData} setAllclearData={setAllclearData} setSignUp={setSignUp} setLoginSigupUp={setLoginSigupUp} />
                    {LoginSigupUp && (<LoginSignupModal setLoginSigupUp={setLoginSigupUp} setSignUp={setSignUp} />)}
                    {SignUp && (<div className='backgroundmodal' onClick={() => ClickOutSideCloseSidebar()}></div>)}
                    <ResponsiveSidebar setSignUp={setSignUp} setLoginSigupUp={setLoginSigupUp} profiledata={profiledata} accessToken={accessToken} is_login={is_login}/>

                    {EighteenModelState && (<EighteenYearsOldModel TermsPravacyState={TermsPravacyState} setTermsPravacyState={setTermsPravacyState} setEighteenModelState={setEighteenModelState} />)}
                    {EighteenModelState && (<div className='backgroundmodalEighteenYearsOldModel' ></div>)}

                    {TermsPravacyState?.open && (<CommonTermsPrivacy TermsPravacyState={TermsPravacyState} setTermsPravacyState={setTermsPravacyState} />)}
                </BrowserRouter>
                <ToastContainer />
        </div>) : (<div className={LicenseErrorMsg === "" ? 'spoofingDetectionModel spoofingDetectionModelActive' : 'spoofingDetectionModel'} style={{ display: "flex" }}>
        <div className="spoofingDetectionModelCONTEND">
            {LicenseErrorMsg !== "" ? (<p>{LicenseErrorMsg}</p>) : BlockMessageShowAsPer()}
            {LicenseErrorMsg !== "" && ButtonShowAfterTime()}
        </div>
    </div>)}
    {process.env.REACT_APP_FACEBOOK_PIXEL_ID && (
        <FacebookPixel id={process.env.REACT_APP_FACEBOOK_PIXEL_ID} />
    )}
    </React.Fragment>
    );
}

export default App;
