/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SecureImage from "../../assets/img/secure.webp";
import CheckoutModal from "../Common/CheckoutModal";
import SuccessModal from "../Common/SuccessModal";
import DefaultProfile from "../../assets/img/DefaultProduct.jpg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CHECK_OUT_PACKAGE, GET_CREDIT_COUPON, POST_PLACE_ORDER_COUPON } from "../Shared/constant";
import GetRequest from "../Common/GetRequest";
import { setStoreCouponPackage } from "../../redux/actions";
import PostRequestAPI from "../Common/PostRequest";
import { toast } from "react-toastify";

const FreeCredit = (props) => {
    const navigate = useNavigate();
    const { setLoginSigupUp } = props;
    const dispatch = useDispatch();
    const { CouponPackage, accessToken } = useSelector((state) => state.allReducers);
    const [loader, setLoader] = useState(false)
    const params = useParams();
    useEffect(()=>{
        if(params?.free_credit){
            var urlString = params?.free_credit;
            var couponPackage = urlString.split('/').pop().split('-').slice(-1)[0];
            callGetCouponPackage(couponPackage.toLocaleUpperCase())
        }else{
            return navigate("/")
        }
    },[params?.free_credit])

    const callGetCouponPackage =async (id) =>{
        const response = await GetRequest(`${GET_CREDIT_COUPON}/${id}`, accessToken);
        if(response?.status === 200){
            dispatch(setStoreCouponPackage(response?.data?.data))
        }else{
            dispatch(setStoreCouponPackage(null));
            navigate("/home")
        }
    }
    function callAPI(state) {
        const data = fetch('https://api.ipify.org/?format=json')
          .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
              return response.json();
          })
          .then((data) => {
              // console.log("data", data)
              localStorage.setItem("IP", data?.ip);
              return data?.ip;
          })
          .catch((error) => {
             return ""
          });
          return data
    };
    const callSubmitForm = async (e) =>{
        e.preventDefault();
        if(accessToken){
            setLoader(true)

            if(document.getElementById("pageisLoading")){
                document.getElementById("pageisLoading").style.display = "flex";
            }            var urlString = params?.free_credit;
            var couponPackage = urlString.split('/').pop().split('-').slice(-1)[0];
            const IP = await callAPI();
            const payload = {
                promo_code: couponPackage.toLocaleUpperCase(),
                ipAddress: IP
            }
            const response = await PostRequestAPI(POST_PLACE_ORDER_COUPON, payload, accessToken);
            if(response?.status == 200){
                toast.success(response?.data?.msg);
                dispatch(setStoreCouponPackage(null));
                navigate("/")
            }else{
                if( typeof response?.data?.error == "string"){
                    toast.info(response?.data?.error)
                }
                if( typeof response?.data?.msg == "string"){
                    toast.info(response?.data?.msg)
                }
            }
            if(document.getElementById("pageisLoading")){
                document.getElementById("pageisLoading").style.display = "none";
            }
            setLoader(false)
        }else{
            setLoginSigupUp(true);
        }
    }
    const PaymentDetails = (props) =>{
        return(
            <div className="container">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="checkoulistwrps" >
                                <h2>Credit - {CouponPackage?.price}</h2>
                                <div className="checkoutlistwrapper">
                                    <div className="checkoutlist">
                                        {CouponPackage?.package_image_path === null ? <img src={DefaultProfile} alt="image"/> : <img src={CouponPackage?.package_image_path} alt="image"/>}
                                        <h5>{CouponPackage?.name}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props?.children}
                        
                    </div>
                </div>
            </div>
        )
    }


    return(
        <section className="checkoutsection">
            <PaymentDetails>
                <div className="col-md-6">
                    <div className="checkoutpaymnt">
                        <h1>Get Credit</h1>
                        <h2>Credit - {CouponPackage?.price}</h2>
                        {/* <Button className="btn clickpaymnt" onClick={() => CheckoutPaymentModal()}>Click here to complete checkout</Button> */}
                        <Button onClick={callSubmitForm} disabled={loader} className="btn clickpaymnt" >Click here to get credit</Button>
                    </div>
                </div>
            </PaymentDetails>
    </section>)
};

export default FreeCredit;