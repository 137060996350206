/* eslint-disable */
import React, { useEffect, useRef } from "react";

const BannerSection = () => {
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, [ ]);

    return(<section className="bannerSectionWrp">
        <video
          playsInline
          loop
          muted
          alt="All the devices"
          src={"https://sweepscoinsgames.s3.us-east-2.amazonaws.com/background-game.mp4"}
          ref={videoEl}
        />
    </section>)
}

export default BannerSection;