/* eslint-disable */
import React, { useEffect } from 'react'
import packageJson from "../package.json";

export default function PageLoad() {
    useEffect(() => {
        clearCacheData();
        caching();
    }, [])
    const clearCacheData = () => {
        if('caches' in window){
            caches.keys().then((names) => {
                console.log("names", names)
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
    };
    const caching = ()=> {
        let version = localStorage.getItem('version');
        if(version!=packageJson.version){
            if('caches' in window){
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });
            
                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }
    
            localStorage.clear();
            localStorage.setItem('version',packageJson.version);
        }
    };
 
  return (
    <div>
      
    </div>
  )
}
