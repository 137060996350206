import React from "react";

const Userdatadeletion = () => {
    return(<section className="privacypage">
        <div className="main-header">
            <div className="container pravcypolicycontainer">
                <div className="row">
                    <div className="col-md-12">
                        <h1>User Data Deletion</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="comnpages">
            <div className="container pravcypolicycontainer">
                <div className="row">
                    <div className="col-md-12">
                        <p>
                        At Sweeps Coins, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Sweeps Coins and how we use it.
                        <br /><br />
                        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regard to the information that they share and/or collect in Sweeps Coins. This policy is not applicable to any information collected offline or via channels other than this website. This privacy policy may change from time to time, so please check it often.
                        <br /><br />
                        <b>Consent</b>
                        </p>
                        <p>
                            By using our website, you hereby acknowledge that you have reviewed our Privacy Policy and agree to its terms. If you do not agree to the terms of our Privacy Policy, please do not use this site.
                            <br /><br />
                            <b>Information we collect</b>
                            <br /><br />
                            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                            <br /><br />
                            When you register for an account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.  In addition, account-holders will be asked to provide information regarding personal identification, banking, and other financial information in order to ensure Anti-Money Laundering compliance and process purchases and/or redemption.  Any information related to personal identification and/or financial information will be kept strictly private and will not be shared in any way.  Sweeps Coins puts forth all reasonable and industry-standard efforts to protect such personal information.
                        </p>
                        <p>
                            <b>How we use your information</b>
                            <br /><br />
                            We use the information we collect in various ways, including but not limited to:

                            <ul>
                                <li>Providing, operating, and maintaining our website</li>
                                <li>Improving, personalizing, and expanding our website</li>
                                <li>Understanding and analyzing how you use our website</li>
                                <li>Developing new products, services, features, and functionality</li>
                                <li>Communicating with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                <li>Sending you emails</li>
                                <li>Finding and prevent fraud</li>
                            </ul>
                            <b>Cookies and Web Beacons</b>
                        </p>
                        <p>
                            Like any other website, Sweeps Coins uses ‘cookies.’ These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
                        </p>
                        <p>
                            <b>Advertising Partners Privacy Policies</b>
                            <br /><br />
                            You may consult this list to find the Privacy Policy for each of the advertising partners of Sweeps Coins.
                            <br /><br />
                            Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Sweeps Coins, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit
                            <br /><br />
                            Note that Sweeps Coins has no access to or control over these cookies that are used Privacy by third-party advertisers.
                        </p>
                        <p>
                            <b>Third Party Privacy Policies</b>
                            <br /><br />
                            Sweeps Coins’ Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                            <br /><br />
                            You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.
                            <br /><br />
                            <b>CCPA Privacy Rights (Do Not Sell My Personal Information)</b>
                            <br /><br />
                            Under the CCPA, among other rights, California consumers have the right to:
                            <br /><br />
                            <ul>
                                <li>Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about that consumer.</li>
                                <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                                <li>Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.</li>
                            </ul>
                            <br /><br />
                            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                        </p>
                        <p>
                            <b>GDPR Data Protection Rights</b>
                            <br /><br />
                            Under General Data Protection Regulations, every user residing in the European Union and/or the European Economic Area is entitled to the following:
                            <br /><br />
                            <ul>
                                <li>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
                                <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
                                <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
                                <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                                <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
                                <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                            </ul>
                            <br /><br />
                            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                        </p>
                        <p>
                            <b>Children's Information</b>
                            <br /><br />
                            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                            <br /><br />
                            Sweeps Coins does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                        </p>
                        <p>
                            <b>Security</b>
                            <br /><br />
                            We operate secure data networks protected by industry standard firewall and password protection systems. Our security and privacy policies are periodically reviewed and enhanced as necessary, and only authorized individuals have access to the personally identifiable information provided by our users. We do not, however, guarantee that unauthorized, inadvertent disclosure will never occur.
                        </p>
                        <p>
                            <br />
                            <b>Transfer of Customer Information</b>
                            <br /><br />
                            Customer lists and information are properly considered assets of a business. Accordingly, if we merge with another entity or if we sell our assets to another entity, our customer lists and information, including personally identifiable information you have provided us, would be included among the assets that would be transferred.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default Userdatadeletion;