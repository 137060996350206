/* eslint-disable */
import React, { useState, useEffect } from "react";
import CustomMendotoryMsg from "../../Common/CustomMendotoryMsg";
import { Button, Image } from "react-bootstrap";
import CountrySelectInput from "../../Common/CountrySelectInput/CountryMobileInput";
import SucessIcon from "../../../assets/img/success.svg";
import SweepsCoinsImage from "../../../assets/img/SWEEPSCOINS-Games.webp";
import { TERMS_CONDITIONS, SIGN_UP_API_URL, PROMOTIONAL_RULES, USER_VALID_LOGIN_API_URL, PRIVACY_POLICY } from '../../Shared/constant';
import { Link } from "react-router-dom";
import { getUser, getAccessToken, checkLogin, IsSiteIsBlockCheck, getOverCallingGeoLocation, getUniqueBrowserId, getRegioLcTime, getGeoCoplyMessage, getGeoCoplyLocation, getSpoofingDetection } from "../../../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "../../../assets/img/information-button.png";

const SignUpSidebar = (props) => {
    const { SignUp, setSignUp, AllclearData, setAllclearData } = props;
    const dispatch = useDispatch();
    const [ fname, setFname ] = useState("");
    const [ lname, setLname ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: "1",
        number: "",
    });
    const [ CustomError, setCustomError ] = useState(false);
    const [ ProvideContent, setProvideContent ] = useState(false);
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\$%\^&\*])(?=.{8,})/;
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);
    const [ updateTermsCondtions, setUpdateTermsCondtions ] = useState(false);
    const { geoComplyLocation, UniqueBrowserId } = useSelector((state) => state.allReducers);

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");
    const [ errorConfirmPassword, setErrorConfirmPassword ] = useState("");
    const [ errorTermsCondtions, setErrorTermsCondtions ] = useState("");
    const [ MobileError, setMobileError ] = useState("");

    // select terms and conditions
    const SelectTermsConditions = (e) => {
        if(e.target.checked) {
            setUpdateTermsCondtions(true);
            setErrorTermsCondtions("");
        } else {
            setUpdateTermsCondtions(false);
            setErrorTermsCondtions("Please accept official sweepstakes rules");
        }
    };

    const SignUpSubmit = () => {
        document.getElementById("signupflag")?.classList?.remove("active");
        if(fname !== "" && lname !== "" && email !== "" && MobileNo?.number?.length === 10 && password !== "" && confirmPassword !== "" && updateTermsCondtions === true) {
            if(EmailRegex.test(email) === true && PasswordRegex.test(password) === true && PasswordRegex.test(confirmPassword) === true && password === confirmPassword) {
                dispatch(getRegioLcTime(""));
                dispatch(getOverCallingGeoLocation(true));
                const jsonData = JSON.stringify({ 
                    first_name: fname, 
                    last_name: lname, 
                    email: email.toLowerCase(), 
                    password: confirmPassword, 
                    phone: MobileNo?.countrycode.toString() + " " + MobileNo?.number.toString()
                });
                window.axios.post(SIGN_UP_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(function (result) {
                    // dispatch(getUser(result?.data?.data?.user));
                    // dispatch(getAccessToken(result?.data?.data?.access_token));
                    // dispatch(checkLogin("yes"));
                    // toast.success(result.data.msg);
                    // closeSidebar();
                    // setErrorTermsCondtions("");
                    // ResetForm();
                    dispatch(getUniqueBrowserId(result?.data?.data?.user_id));
                    GetGeoLicenceFunction("signup", result?.data?.data?.user_id, result?.data?.data?.token); 
                })  
                .catch(function (result) {
                    dispatch(getUser(""));
                    dispatch(checkLogin("no"));
                    dispatch(getAccessToken(""));
                    toast.error(result.response.data.error);
                });
                setErrorEmail("");
                setErrorPassword("");
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
                if(PasswordRegex.test(password) === false) {
                    setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character");
                } else {
                    setErrorPassword("");
                }
                if(PasswordRegex.test(confirmPassword) === false) {
                    setErrorConfirmPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character");
                } else if(password !== confirmPassword) {
                    setErrorConfirmPassword("Your password and confirm password do not match.");
                } else {
                    setErrorConfirmPassword("");
                }
                if(MobileNo?.number?.length < 10) {
                    setMobileError("Please enter 10 digit number");
                } else {
                    setMobileError("");
                }
            }
            setCustomError(false);
        } else {
            setCustomError(true);
            if(!updateTermsCondtions) {
                setErrorTermsCondtions("Please accept official sweepstakes rules");
            };
            // document.getElementById("sweepstakesrules").checked = false;
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if (password === "") {
                setErrorPassword("Password cannot be empty");
            } else if(PasswordRegex.test(password) === false) {
                setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.")
            } else {
                setErrorPassword("");
            }
            if (confirmPassword === "") {
                setErrorConfirmPassword("Confirm password cannot be empty");
            } else if(PasswordRegex.test(confirmPassword) === false) {
                setErrorConfirmPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.")
            } else {
                if(password !== confirmPassword) {
                    setErrorConfirmPassword("Your password and confirm password do not match.");
                } else {
                    setErrorConfirmPassword("");
                }
            }
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.length < 10) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        };
    };

    const GetGeoLicenceFunction = async (url, id, token) => {
        document.getElementById("pageisLoading").style.display = "flex";
        window.axios.get(`${process.env.REACT_APP_BASE_URL}user/geocomply/license/${id}`, {
        headers: {
            'Content-Type': 'application/json',
        }}).then(function (result) {
            document.getElementById("signupflag")?.classList?.remove("active");
            if(result?.status === 200) {
                GetGeoLocate(result?.data?.data?.license, url, id, token);
                dispatch(getGeoCoplyMessage(""));
            };
        }).catch(function (result) {
            document.getElementById("signupflag")?.classList?.remove("active");
            dispatch(getGeoCoplyMessage(""));
        });
    };
    
    // Geo location api
    const GetGeoLocate = (jsonData, url, id, token) => {
        var geoClient = window.GcHtml5.createClient();
        geoClient.setUserId(id);
        geoClient.setReason(url);
        geoClient.setLicense(jsonData);
        geoClient.events.on('hint', function(reason, data) {
        }).on('engine.success', function(text, xml) {
            LocationPermission(text, id, token);
            dispatch(getGeoCoplyLocation(text));
            // localStorage.setItem("GeoLocationToken", text);
        }).on('*.failed', function(code, message) {
            if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                    //e.g. update license and retry geolocating
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                }
            } else if (this.event === 'browser.failed') {
                //it's safe to be ignored, you can remove this block or add your own logic
            }
        });
        geoClient.request();
    };
    
    // callback call
    const callGeoTimeApi = async (re_geolocation) => {
        if(re_geolocation !== null) {
            var reGeoLcTime = re_geolocation * 1000;
            localStorage.setItem("reGeoLcTime", reGeoLcTime.toString());
            var currentTimeMillis = Date.now();
            var currentTimeString = currentTimeMillis.toString();
            localStorage.setItem("reGeoLcTimeStamp", currentTimeString);
            setTimeout(() => {
                GetGeoLicenceFunction("Periodic");
            }, reGeoLcTime);
        }
    };
    
    // location Permissions api
    const LocationPermission = async (text, id, token) => {
        const jsonData = JSON.stringify({ 
            "id": id,
            "data": text
        });
        window.axios.post(`${process.env.REACT_APP_BASE_URL}user/geocomply/permission`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            if(result?.status === 200) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
                if(result?.data?.data?.status === true) {
                    dispatch(getOverCallingGeoLocation(false));
                    SignupFunctionGeo(token, text);
                    // success code
                    // callGeoTimeApi(result?.data?.data?.re_geolocation);
                    dispatch(getRegioLcTime(result?.data?.data?.re_geolocation));
                    localStorage.removeItem("starttime");
                    localStorage.removeItem("targettime");
                }
                dispatch(getSpoofingDetection(result?.data?.data?.spoofing_detection));
                if(result?.data?.data?.status === false) {
                    document.getElementById("pageisLoading").style.display = "none";
                    dispatch(getGeoCoplyMessage(result?.data?.data?.troubleshooter));
                    if(result?.data?.data?.spoofing_detection != null){
                        // add spoofing detection popup
                        console.log("spoofing detection");
                    }
                }
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
            
        })  
        .catch(function (result) { 
        document.getElementById("pageisLoading").style.display = "none";
        if(result?.response?.data?.error === "Geo-comply failed.") {
            // localStorage.setItem("UniquID", "id" + Math.random().toString(16).slice(2));
        } else if(result?.status === 400) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
        };
        });
    };
    
    // signup function step first
    const SignupFunctionGeo = (token, text) => {
        const jsonData = JSON.stringify({ 
            "token" : token, 
            "location" : text 
        });
        window.axios.post(USER_VALID_LOGIN_API_URL, jsonData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }}).then(function (result) {
            document.getElementById("pageisLoading").style.display = "none";
            dispatch(getUser(result?.data?.data?.user));
            dispatch(getAccessToken(result?.data?.data?.access_token));
            localStorage.setItem("access_tokens", result?.data?.data?.access_token);
            dispatch(checkLogin("yes"));
            toast.success(result.data.msg);
            closeSidebar();
            setErrorTermsCondtions("");
            ResetForm();
            document.getElementById("signupflag")?.classList?.remove("active");
        }).catch(function (result) {
            document.getElementById("pageisLoading").style.display = "none";
            document.getElementById("signupflag")?.classList?.remove("active");
            dispatch(getUser(""));
            dispatch(checkLogin("no"));
            dispatch(getAccessToken(""));
            toast.error(result.response.data.error);
        });   
    };

    useEffect(() => {
        if(AllclearData === true) {
            ResetForm();
            document.getElementById("signupflag")?.classList?.remove("active");
            setTimeout(() => {
                setAllclearData(false);
            }, 200);
        };
    }, [ AllclearData ]);

    // clear for data
    const ResetForm = () => {
        setFname("");
        setLname("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setErrorEmail("");
        setErrorTermsCondtions("");
        setErrorPassword("");
        setErrorConfirmPassword("");
        setMobileError("");
        setShowPassword(false);
        document.getElementById("signupflag")?.classList?.remove("active");
        document.getElementById("termsconditions").checked = false;
        document.getElementById("sweepstakesrules").checked = false;
        setMobileNo({...MobileNo, number: ""})
    };

    // close sidebar function
    const closeSidebar = (url) => {
        if(url !== undefined) {
            setTimeout(() => {
                setSignUp(false);
                document.getElementById("signupflag")?.classList?.remove("active");
            }, 100)
        } else {
            setSignUp(false);
            setCustomError(false);
            setProvideContent(false);
            setErrorEmail("");
            setErrorPassword("");
            setErrorTermsCondtions("");
            ResetForm();
            document.getElementById("sweepstakesrules").checked = false;
            document.getElementById("termsconditions").checked = false;
            document.getElementById("signupflag")?.classList?.remove("active");
        }
    };

    return(<div className={SignUp === true ? "sidebarcommon open" : "sidebarcommon"}>
        <h2>SIGN UP
            <svg onClick={() => closeSidebar()} preserveAspectRatio="xMidYMid meet" data-bbox="82.189 55.096 481.811 481.808" xmlns="http://www.w3.org/2000/svg" viewBox="82.189 55.096 481.811 481.808" role="presentation" aria-hidden="true">
                <g>
                    <path d="M531.936 536.904L323.094 328.063 114.253 536.904l-32.064-32.062L291.032 296 82.189 87.157l32.064-32.061 208.842 208.842L531.936 55.096 564 87.157 355.155 296 564 504.842l-32.064 32.062z"></path>
                </g>
            </svg>
        </h2>
        <p>Join Sweepscoins.games and Play To Win!</p>
        <div className="paymentformsWraps signupfields">
            <div className="termscondtaceptsection">
                <div className="form-group-checkbox">
                    <input type="checkbox" id="termsconditions" />
                    <label htmlFor="termsconditions">I accept terms & conditions <Link to={TERMS_CONDITIONS} onClick={() => closeSidebar("checkTermsfile")} >View terms of use</Link></label>
                </div>
                <div className="form-group-checkbox">
                    <input type="checkbox" id="sweepstakesrules" onChange={(e) => SelectTermsConditions(e)} />
                    <label htmlFor="sweepstakesrules">I accept official sweepstakes rules <Link to={TERMS_CONDITIONS} onClick={() => closeSidebar("checkTermsfile")} >View terms of use</Link></label>
                    {errorTermsCondtions !== "" && <div className="danger-color">{errorTermsCondtions}</div>}
                </div>
            </div>
            <div className="rowcustom rowcustom-col-2">
                <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                    <label>First Name *</label>
                    <div className="form-groupfiled">
                        <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value.trim())} placeholder="Enter First Name" autoComplete="off"/>
                        {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First name"} />}
                    </div>
                </div>
                <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                    <label>Last Name *</label>
                    <div className="form-groupfiled">
                        <input type="text" className="form-control" value={lname} onChange={(e) => setLname(e.target.value.trim())} placeholder="Enter Last Name" autoComplete="off"/>
                        {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last name"} />}
                    </div>
                </div>
            </div>
            <div className="rowcustom rowcustom-col-2">
                <div className={(errorEmail !== "" && EmailRegex.test(email) === false) ? "form-group error" : "form-group"}>
                    <label>Email *</label>
                    <div className="form-groupfiled">
                        <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} placeholder="Enter Email" autoComplete="off"/>
                        {EmailRegex.test(email) === true && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        {EmailRegex.test(email) === false && <div className="danger-color">{errorEmail}</div>}
                    </div>
                </div>
                <div className={(MobileError !== "" && MobileNo?.number?.toString()?.length !== 10) ? "form-group error" : "form-group"}>
                    <label>Phone</label>
                    <div className="form-groupfiled">
                        <CountrySelectInput 
                            MobileNo={MobileNo}
                            setMobileNo={setMobileNo}
                            id={"signupflag"}
                        />
                        {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-color">{MobileError}</div>}
                    </div>
                </div>
            </div>
            <div className="rowcustom rowcustom-col-2 paswordfield">
                <div className={(errorPassword !== "" && PasswordRegex.test(password) !== true) ? "form-group error" : "form-group"}>
                    <label>Password *
                        <div className="passwordnotewrapper">
                            <ol>
                                {/* Minimum 8 characters. */}
                                <li>The alphabet must be between [a-z]</li>
                                <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                <li>At least 1 number or digit between [0-9].</li>
                                <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                            </ol>
                            <Image src={InfoIcon} alt="info icon" />
                        </div>
                    </label>
                    <div className="form-groupfiled passwordboxset">
                        <input type="password" className="d-none" style={{ display: "none" }} />
                        <input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e) => setPassword(e.target.value.trim())} placeholder="Enter Password" autoComplete="new-password"/>
                        <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                        </span>
                        {PasswordRegex.test(password) !== true && <div className="danger-colorset">{errorPassword}</div>}
                    </div>
                </div>
                <div className={(errorConfirmPassword !== "" && PasswordRegex.test(confirmPassword) !== true) ? "form-group error" : "form-group"}>
                    <label>Confirm Password *
                        <div className="passwordnotewrapper">
                            <ol>
                                {/* Minimum 8 characters. */}
                                <li>The alphabet must be between [a-z]</li>
                                <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                <li>At least 1 number or digit between [0-9].</li>
                                <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                            </ol>
                            <Image src={InfoIcon} alt="info icon" />
                        </div>
                    </label>
                    <div className="form-groupfiled passwordboxset">
                        <input type="password" className="d-none" style={{ display: "none" }} />
                        <input type={showConfirmPassword ? "text" : "password"} className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Enter Confirm Password" autoComplete="new-password" />
                        <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                        </span>
                        {errorConfirmPassword !== "" && <div className="danger-colorset">{errorConfirmPassword}</div>}
                    </div>
                </div>
            </div>
            <div className="rowcustom rowcustom-col-2">
                <div className="form-group">
                    <Button className="btn formcomnbtnsidbr" onClick={() => SignUpSubmit()}>Submit</Button>
                </div>
            </div>
        </div>
        <div className="prvidethiswhy">
            <img src={SweepsCoinsImage} alt="Sweeps Coins" />
            <div className="prvidethiswhytoggleinfo">
                <div onClick={() => setProvideContent(!ProvideContent)}>Why do I need to provide this?</div>
                {ProvideContent && (<p>Sweeps Coins is an authorized sponsor of promotional sweepstakes games.  In order to comply with local and federal laws, including anti-money laundering laws, sweepstakes laws, and tax laws, we are required to collect certain personal information from sweepstakes participants before we can award prizes.  The requirement that we collect such information is also spelled out in our <Link to={TERMS_CONDITIONS} onClick={() => closeSidebar("checkTermsfile")} >Terms & Conditions</Link> and <Link to={PROMOTIONAL_RULES} onClick={() => closeSidebar("checkTermsfile")}>Promotional Rules</Link>.  We value your privacy, and we will not use your personal information for any unauthorized reasons.  Please see our <Link to={PRIVACY_POLICY} onClick={() => closeSidebar("checkTermsfile")}>Privacy Policy</Link> for further information on how we protect your private information.</p>)}
            </div>
        </div>
    </div>);
}

export default SignUpSidebar;