/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import SecureImage from "../../assets/img/secure.webp";
import CheckoutModal from "../Common/CheckoutModal";
import SuccessModal from "../Common/SuccessModal";
import DefaultProfile from "../../assets/img/DefaultProduct.jpg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CHECK_OUT_PACKAGE } from "../Shared/constant";
import RightArrowIcon from "../../assets/img/RightArrowIcon.svg";

const Checkout = (props) => {
    const navigate = useNavigate();
    const { setLoginSigupUp, order_id } = props;
    const { selectedPosters, accessToken, is_login, customerDetail } = useSelector((state) => state.allReducers);

   
    const [ checkoutPoup, setCheckoutPoup ] = useState({
        open: false,
        title: "",
    });
    const [ SuccessPopup, setSuccessPopup ] = useState({
        open: false,
        title: ""       
    })
    const { state } = useLocation();
    const params = useParams();

    useEffect(()=>{
        if(order_id == selectedPosters?.id){
            CheckoutPaymentModal()
        }
    },[order_id == selectedPosters?.id])
    // console.log("state", params,selectedPosters, checkoutPoup)
    // checkout popup 
    const CheckoutPaymentModal = () => {
        if(accessToken === "" && is_login === "no") {
            setLoginSigupUp(true);
        } else {
            setCheckoutPoup({...checkoutPoup, open: !checkoutPoup?.open, title: "Checkout"});
        }
    };
    const callCheckoutPayment = () => {
        if(accessToken === "" && is_login === "no") {
            setLoginSigupUp(true);
        } else {
            navigate(`${CHECK_OUT_PACKAGE}/${selectedPosters?.id}`, {state: selectedPosters})
        }
    };
    const PaymentDetails = (props) =>{
        return(
            <div className="container">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="checkoulistwrps" >
                                <h2>Price - ${Number(selectedPosters?.price).toLocaleString()}</h2>
                                <div className="checkoutlistwrapper">
                                    <div className="checkoutlist">
                                        {selectedPosters?.package_image_path === null ? <img src={DefaultProfile} alt="image"/> : <img src={selectedPosters?.package_image_path} alt="image"/>}
                                        <h5>{selectedPosters?.name}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props?.children}
                        
                    </div>
                </div>
            </div>
        )
    }

    if(order_id == selectedPosters?.id){
        return(
        <section className="checkoutsection checkout-payment pt-sm-5 pt-4">
            <div className="container">
                <div className="col-md-12">
                    <div className="row d-flex">
                        <div className="col-md-6 mx-auto">
                            <CheckoutModal modalState={false} selectedProduct={selectedPosters} SuccessPopup={SuccessPopup} setSuccessPopup={setSuccessPopup} checkoutPoup={checkoutPoup} setCheckoutPoup={setCheckoutPoup}  />
                            {SuccessPopup?.open && (<SuccessModal SuccessPopup={SuccessPopup} setSuccessPopup={setSuccessPopup} />)}
                            {(SuccessPopup?.open) && (<div className="ModalBackground"></div>)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
    return(
        <section className="checkoutsection">
            <PaymentDetails>
                <div className="col-md-6">
                    <div className="checkoutpaymnt">
                        <h1>Checkout</h1>
                        <h2>Price - ${Number(selectedPosters.price)?.toLocaleString()}</h2>
                        {/* <Button className="btn clickpaymnt" onClick={() => CheckoutPaymentModal()}>Click here to complete checkout</Button> */}
                        <Button className="btn clickpaymnt right-Icon" onClick={callCheckoutPayment}>Click here to complete checkout <img src={RightArrowIcon} /></Button>
                        <img src={SecureImage} className="secureimage" alt="SecureImage" />
                    </div>
                </div>
            </PaymentDetails>
        {/* checkout modal start here */}
        {checkoutPoup?.open && (<CheckoutModal modalState={false} selectedProduct={selectedPosters} SuccessPopup={SuccessPopup} setSuccessPopup={setSuccessPopup} checkoutPoup={checkoutPoup} setCheckoutPoup={setCheckoutPoup}  />)}
        {SuccessPopup?.open && (<SuccessModal SuccessPopup={SuccessPopup} setSuccessPopup={setSuccessPopup} />)}
        {(checkoutPoup?.open || SuccessPopup?.open) && (<div className="ModalBackground"></div>)}
    </section>)
};

export default Checkout;