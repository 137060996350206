/* eslint-disable */
import React, { useState, useEffect } from "react";
import CommonDropdown from "../../Common/CommonDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HOME_URL, HOME_LANDING_URL, LOGOUT_API_URL, PACKAGES, ALL_SESSION_LOGOUT_API_URL, MEMBER_DASHBOARD, MY_ACCOUNT, PRIVACY_POLICY, TERMS_CONDITIONS, PROMOTIONAL_RULES, CONTACT } from "../constant";
import Logo from "../../../assets/img/sweepcoinscash-01.webp";
import BundlesOfbookLogo from "../../../assets/img/BundlesOfBooks.webp";
import MotivateOnPapeLogo from "../../../assets/img/MotivateonPape.webp";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import LogoutIcon from "../../../assets/img/logout.png";
import { getAccessToken, checkLogin, getUser, getRegioLcTime, accountNavigate, CallLogoutUser } from "../../../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from 'react-bootstrap/Dropdown';

import StoreFrontImage from "../../../assets/img/header/Storefront.png";
import PaperPlaneTiltImage from "../../../assets/img/header/PaperPlaneTilt.png";
import BookImage from "../../../assets/img/header/Book.png";
import CoinsImage from "../../../assets/img/header/Coins.png";
import ShowMoreImage from "../../../assets/img/header/CaretCircleRight.svg";
import CurrencyDollarImage from "../../../assets/img/header/CurrencyDollar.svg";

const Header = (props) => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const { setSignUp, setLoginSigupUp, setLocationGet } = props;
    const [ Dropdwon, setDropdwon ] = useState(false);
    const LocationUrl = useLocation();
    const { profiledata, accessToken, is_login, accountUrl } = useSelector((state) => state.allReducers);

    useEffect(() => {
        if(LocationUrl.pathname) {
            setLocationGet(LocationUrl.pathname);
        }
    }, [ LocationUrl ]);

    useEffect(() => {   
        if(LocationUrl.pathname === HOME_URL) {
            document.title = "Online Sweepstakes Games | Sweeps Coins | Fun & Interactive Games ";
        } else if (LocationUrl.pathname === HOME_LANDING_URL) {
            document.title = "Home - Landing Page | Sweeps Coins Games";
        } else if (LocationUrl.pathname === PRIVACY_POLICY) {
            document.title = "Privacy Policy Statement | Sweeps Coins";
        } else if (LocationUrl.pathname === TERMS_CONDITIONS) {
            document.title = "Terms & Conditions | Sweeps Coins ";
        } else if (LocationUrl.pathname === PROMOTIONAL_RULES) {
            document.title = "Official Promotion Rules | Sweeps Coins";
        } else if (LocationUrl.pathname === CONTACT) {
            document.title = "Contact | Sweeps Coins";
        } else if (LocationUrl.pathname === MEMBER_DASHBOARD) {
            document.title = "Member Dashboard";
        } else if (LocationUrl.pathname === MY_ACCOUNT) {
            document.title = "Contact | Sweeps Coins";
        } else if (LocationUrl.pathname === PACKAGES) {
            document.title = "Packages | Sweeps Coins";
        }
    }, [ LocationUrl.pathname ]);

    const openRespSidebar = () => {
        var element = document.getElementById("responsiveSidebarMain");
        element.classList.toggle("active");
    };

    // logout function
    const Logout = () => {
        window.axios.get(LOGOUT_API_URL, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setTimeout(() =>  Navigate(HOME_URL), 200);
            localStorage.removeItem("accessToken");
            localStorage.removeItem("starttime");
            localStorage.removeItem("targettime");
            localStorage.removeItem("access_tokens");
            dispatch(getAccessToken(""));
            dispatch(getRegioLcTime(""));
            dispatch(checkLogin("no"));
            toast.success(result?.data?.msg);
        })  
        .catch(function (result) {
            toast.error(result?.response?.data?.detail);
            if(result?.response?.data?.detail === "Token expired.") {
                AccessTokenCheckLogout();
            } else {
                AccessTokenCheckLogout();
            }
        });
    };

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        if(screen.width < 800) {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }
        setTimeout(() =>  Navigate(HOME_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        dispatch(getUser(""));
    };

    // Claim Rewards function
    const ClaimRewardBtn = () => {
        if(accessToken === "" && is_login === "no") {
            setLoginSigupUp(true);
        }else {
            Navigate(MY_ACCOUNT);
            dispatch(accountNavigate("my-books"));
            if(screen.width < 800) {
                var element = document.getElementById("responsiveSidebarMain");
                element.classList.remove("active");
            };
        }
    };


    useEffect(() => {
        if(accessToken !== "") {
            window.axios.get(ALL_SESSION_LOGOUT_API_URL, {
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }}).then(function (result) {
                if(result?.status === 200) {
                
                };
            }).catch(function (result) {
                if(result?.response?.status === 403) {
                    toast.error("The session expired due to logging in on another device.");
                    dispatch(CallLogoutUser());
                    localStorage.removeItem("access_tokens");
                    Navigate(HOME_URL)
                };
            });
        }
    }, [ accessToken, LocationUrl, is_login, accountUrl ]);

    return(<header className={accessToken !== "" && is_login === "yes" ? "Header_section Header_after_login" : "Header_section"}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    {screen.width > 1100 ? (<div className="logogheader">
                        <Link to={HOME_URL} className="logohere">
                            <img src={Logo} alt="" />
                        </Link>
                        <div className="NewHeader_wrapper">
                            <div className="logo_main_wrapper">
                                <div className="NewHeader_wrapper_flex">
                                    <h3>Earn a 100% Match on your FIRST Purchase!! </h3>
                                    <div className="newheader_columns" onClick={() => Navigate(PACKAGES)}>
                                        <img src={CoinsImage} alt="Play Now with Buy Coins" />
                                        <h4>Buy Bundles Play Now</h4>
                                        <small>Shop Now <img src={ShowMoreImage} alt="view more"/></small>
                                    </div>
                                    <a hidden href={process.env.REACT_APP_SITE === "STAGING" ? "https://mp.appristine.in/" : "https://motivateonpape.com/"} target="_blank" className="newheader_columns">
                                        <img src={PaperPlaneTiltImage} alt="Play Now with Buy Coins" />
                                        <h4>Motivate on Pape</h4>
                                        <small className="titleanchor">Shop Now <img src={ShowMoreImage} alt="view more"/></small>
                                    </a>
                                    <a hidden href={process.env.REACT_APP_SITE === "STAGING" ? "https://bb.appristine.in/" : "https://bundlesofbooks.com/"} target="_blank" className="newheader_columns">
                                        <img src={BookImage} alt="Play Now with Buy Coins" />
                                        <h4>Bundles of Books</h4>
                                        <small className="titleanchor">Shop Now <img src={ShowMoreImage} alt="view more"/></small>
                                    </a>
                                    <a href={process.env.REACT_APP_SITE === "STAGING" ? "https://scc.appristine.in/my-account" : "https://www.sweepscoins.cash/my-account"} target="_blank" className="newheader_columns">
                                        <img src={CurrencyDollarImage} alt="Play Now with Buy Coins" />
                                        <h4>Claim Rewards</h4>
                                        <small className="titleanchor">Redeem Now <img src={ShowMoreImage} alt="view more"/></small>
                                    </a>
                                </div>
                                <div hidden className="NewHeader_wrapper_flex">
                                    <a href={process.env.REACT_APP_SITE === "STAGING" ? "https://scc.appristine.in/my-account" : "https://www.sweepscoins.cash/my-account"} target="_blank" className="newheader_columns">
                                        <img src={CurrencyDollarImage} alt="Play Now with Buy Coins" />
                                        <h4>Claim Rewards</h4>
                                        <small className="titleanchor">Redeem Now <img src={ShowMoreImage} alt="view more"/></small>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="header_login_detas">
                            <h3>Claim Your Free Entries & Play To Earn Cash Prizes</h3>
                            <div className="header_login_wrapper" style={{ width: (accessToken !== "" && is_login !== "no") ? "100%" : "auto" }}>
                                {(accessToken === "" && is_login === "no") ? (<>
                                    <div className="headebtn">
                                        <span>(Returning Gamers)</span>
                                        {/* <a href="javscript:void(0);" className="btn signbtn" onClick={() => setLoginSigupUp(true)}>Sign In</a> */}
                                        <button className="btn signbtn" onClick={() => setLoginSigupUp(true)}>Sign In</button>
                                    </div>
                                    <div className="headebtn">
                                        <span>(New Users)</span>
                                        {/* <a href="javscript:void(0);" className="btn createminbtn" onClick={() => setSignUp(true)}>Create a free profile</a> */}
                                        <button className="btn createminbtn" onClick={() => setSignUp(true)}>Create a free profile</button>
                                    </div>
                                </>) : (<div className="userLoginSignupwraps">
                                    <div className={(accessToken === "" && is_login === "no") ? "userLoginSignupwrapsrow userLoginSignupwrapsrowRelative" : "userLoginSignupwrapsrow"}>
                                        <div className="loginbtnbx">
                                            Your Account :  
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <h1 className="accountlogin" onClick={() => setDropdwon(!Dropdwon)}>
                                                        {(profiledata?.first_name !== "" && profiledata?.last_name !== "") ? profiledata?.first_name + " " + profiledata?.last_name : profiledata?.email?.split("@")[0]} 
                                                        <svg className="dropdousericon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><g><g><polygon points="13,20.4 0,7.4 1.8,5.6 13,16.8 24.2,5.6 26,7.4   "></polygon></g></g></svg>
                                                    </h1>
                                                </Dropdown.Toggle>
                                                <CommonDropdown setDropdwon={setDropdwon} accessToken={accessToken} />
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>) : (<div className="logogheader">
                        <div className="userLoginSignupwraps">
                            <div className={(accessToken === "" && is_login === "no") ? "userLoginSignupwrapsrow userLoginSignupwrapsrowRelative" : "userLoginSignupwrapsrow"}>
                                <Link to={HOME_URL} className="logohere">
                                    <img src={Logo} alt="" />
                                </Link>

                                {/* {(accessToken !== "" && is_login !== "no") && (<div className="loginbtnbx login">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <h1 className="accountlogin" onClick={() => setDropdwon(!Dropdwon)}>
                                                {(profiledata?.first_name !== "" && profiledata?.last_name !== "") ? profiledata?.first_name + " " + profiledata?.last_name : profiledata?.email?.split("@")[0]} 
                                            </h1>
                                                <svg className="dropdousericon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><g><g><polygon points="13,20.4 0,7.4 1.8,5.6 13,16.8 24.2,5.6 26,7.4   "></polygon></g></g></svg>
                                        </Dropdown.Toggle>
                                        <CommonDropdown setDropdwon={setDropdwon} accessToken={accessToken} />
                                    </Dropdown>
                                </div>)} */}
                            </div>
                        </div>
                        <div className="userLoginSignupwraps userLoginSignupwrapswraps">
                            <div className="NewHeader_wrapper">
                                <div className="logo_main_wrapper">
                                <div className="NewHeader_wrapper_flex">
                                    <h3>Earn a 100% Match on your FIRST Purchase!!</h3>
                                    <div className="newheader_columns">
                                        <img src={CoinsImage} alt="Play Now with Buy Coins" />
                                        <h4>Buy Coins Play Now</h4>
                                        <small onClick={() => Navigate(PACKAGES)}>Shop Now <img src={ShowMoreImage} alt="view more"/></small>
                                    </div>
                                    <div hidden className="newheader_columns">
                                        <img src={PaperPlaneTiltImage} alt="Play Now with Buy Coins" />
                                        <h4>Motivate on Pape</h4>
                                        <a href="https://motivateonpape.com/" target="_blank">Shop Now <img src={ShowMoreImage} alt="view more"/></a>
                                    </div>
                                    <div hidden className="newheader_columns">
                                        <img src={BookImage} alt="Play Now with Buy Coins" />
                                        <h4>Bundles of Books</h4>
                                        <a href="https://bundlesofbooks.com/" target="_blank">Shop Now <img src={ShowMoreImage} alt="view more"/></a>
                                    </div>
                                    <div className="newheader_columns">
                                        <img src={CurrencyDollarImage} alt="Play Now with Buy Coins" />
                                        <h4>Claim Rewards</h4>
                                        <small onClick={() => ClaimRewardBtn()}>Redeem Now <img src={ShowMoreImage} alt="view more"/></small>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="togglebuttonrespo" onClick={() => openRespSidebar()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </header>);
}

export default Header;