/* eslint-disable */
import React from "react";
import Logo from "../../assets/img/sweepcoinscash-back.webp";

const EighteenYearsOldModel = (props) => {
    const { setEighteenModelState, setTermsPravacyState, TermsPravacyState } = props;

    // allow to view site
    const AllowSite = () => {
        setEighteenModelState(false);
        sessionStorage.setItem("SiteBlockChecking", "no");
    };

    // redirect to google site
    const BlockSite = () => {
        window.location.replace('https://www.google.com/');
    };

    // privacy policy page link
    const privacyPolicyLink = () => {
        setTermsPravacyState({...TermsPravacyState, open: !TermsPravacyState?.open, Url: "PRIVACY_POLICY"});
    };

    // terms and conditions page link
    const termsAndCondtionsLink = () => {
        setTermsPravacyState({...TermsPravacyState, open: !TermsPravacyState?.open, Url: "TERMS_CONDITIONS"});
    };

    return(<div className="EighteenYearsOldModelWrapper">
        <img src={Logo} alt="logo" />
        <h1>Welcome</h1>
        <p>Are you older than 18?</p>
        <div className="btngroupmange">
            <button type="button" className="btn yesbtn" onClick={() => AllowSite()}>Yes</button>
            <button type="button" className="btn nobtn" onClick={() => BlockSite()}>No</button>
        </div>
        <div className="lastmessageterms">By accessing this site, you accept the 
            <a href="javascript:void(0);" onClick={() => privacyPolicyLink()}>Privacy Policy</a> and 
            <a href="javascript:void(0);" onClick={() => termsAndCondtionsLink()}>Terms and Conditions</a>
        </div>
    </div>)
};

export default EighteenYearsOldModel;