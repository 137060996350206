/* eslint-disable */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MY_ACCOUNT, LOGOUT_API_URL, HOME_URL } from "../../Shared/constant";
import { accountNavigate, getAccessToken, checkLogin, getUser } from "../../../redux/actions/index";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from 'react-bootstrap/Dropdown';


const CommonDropdown = (props) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { setDropdwon, accessToken } = props;

    // Member dashboard link route
    const MemberDashboard = () => {
        Navigate(MY_ACCOUNT);
        dispatch(accountNavigate("my-books"));
        if(screen.width < 800) {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        };
        setDropdwon(false);
    };

    // my account link route
    const MyAccountLink = () => {
        Navigate(MY_ACCOUNT);
        dispatch(accountNavigate("my-account"));
        if(screen.width < 800) {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        };
        setDropdwon(false);
    };

    // logout function
    const Logout = () => {
        window.axios.get(LOGOUT_API_URL, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setTimeout(() =>  Navigate(HOME_URL), 200);
            localStorage.removeItem("accessToken");
            localStorage.removeItem("access_tokens");
            dispatch(getAccessToken(""));
            dispatch(checkLogin("no"));
            toast.success(result?.data?.msg);
            setDropdwon(false);
        })  
        .catch(function (result) {
            setDropdwon(false);
            // toast.error(result?.response?.data?.detail);
            // if(result?.response?.data?.detail === "Token expired.") {
            //     AccessTokenCheckLogout();
            // } else {
                AccessTokenCheckLogout();
            // }
        });
    };

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        if(screen.width < 800) {
            var element = document.getElementById("responsiveSidebarMain");
            element.classList.remove("active");
        }
        setTimeout(() =>  Navigate(HOME_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        dispatch(getUser(""));
    };

    return(
    // <div className="CommonDropdownWraps">
    //     <ul>
    //         <li>
    //             <Link to={MY_ACCOUNT} onClick={() => MemberDashboard()}>Member Dashboard</Link>
    //         </li>
    //         <li>
    //             <Link to={MY_ACCOUNT} onClick={() => MyAccountLink()}>My Account</Link>
    //         </li>
    //         <li>
    //             <a  onClick={() => Logout()} >Logout</a>
    //         </li>
    //     </ul>
    // </div>
    <Dropdown.Menu className="CommonDropdownWraps">
        <Dropdown.Item onClick={() => MemberDashboard()}>
            Member Dashboard
        </Dropdown.Item>
        <Dropdown.Item onClick={() => MyAccountLink()}>
            My Account
        </Dropdown.Item>
        <Dropdown.Item onClick={() => Logout()} >
           Logout
        </Dropdown.Item>
        {/* <ul>
             <li className="dropdown-item">
                 <Link to={MY_ACCOUNT} onClick={() => MemberDashboard()}>Member Dashboard</Link>
             </li>
             <li className="dropdown-item">
                 <Link to={MY_ACCOUNT} onClick={() => MyAccountLink()}>My Account</Link>
             </li>
             <li className="dropdown-item">
                 <a  onClick={() => Logout()} >Logout</a>
             </li>
         </ul> */}
    </Dropdown.Menu>
    );
}

export default CommonDropdown;