/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}
const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.onload = () => resolve(true);
    script.onerror = () => reject(false);
    document.head.appendChild(script);
  });
};
const CashPayComponent = (props) => {
  const UUID = uuidv4()
  const dispatch = useDispatch();
  const [status, setStatus] = useState("")
  const appId = process.env.REACT_APP_SQUARE_APP_ID;
  const locationId = process.env.REACT_APP_SQUARE_APP_LOCATION_ID;
  //   const appId = 'sandbox-sq0idb-c6wLY2ZTwC9vzH_LCLJ67w';
  //   const locationId = 'LCXW36BEMGS4V';
  let cashAppPayInstance; // Define a variable to hold the Cash App Pay instance

  // console.log("props?.amount", props?.amount)
  const buildPaymentRequest = async (payments) => {
    const getID = await props?.PaymentContinueStepGeo("cash_app");
    const paymentRequest = payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: parseFloat(props?.amount).toFixed(),
        label: 'Total',
      },
    });
    return {paymentRequest: paymentRequest, orderDetails: getID};
  };

  const initializeCashApp = async (payments) => {
    document.getElementById("pageisLoading").style.display = "flex";
    const { paymentRequest, orderDetails } = await buildPaymentRequest(payments);
    const cashAppPay = await payments.cashAppPay(paymentRequest, {
      redirectURL: `${window.location.href}/${orderDetails?.id}`,
      referenceId: UUID,
    });
    const buttonOptions = {
      shape: 'semiround',
      width: 'full',
    };
    await cashAppPay.attach('#cash-app-pay', buttonOptions);
    setTimeout(()=>document.getElementById("pageisLoading").style.display = "none", [500])
    return cashAppPay;
  };

  const createPayment = async (token) => {
    const body = JSON.stringify({
      locationId,
      sourceId: token,
      idempotencyKey: window.crypto.randomUUID(),
    });
    await dispatch(await props?.callBackInitPayment(body))
    // const paymentResponse = await fetch('/payment', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body,
    // });

    // if (paymentResponse.ok) {
    //   return paymentResponse.json();
    // }

    // const errorBody = await paymentResponse.text();
    // throw new Error(errorBody);
  };

  const displayPaymentResults = (status) => {
    const statusContainer = document.getElementById('payment-status-container');
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  };

  useEffect(() => {
    const initializeSquarePayment = async () => {
      try {
        // Load the CDN script dynamically
        const isScriptLoaded = await loadScript(process.env.REACT_APP_SQUARE_CDN_URL);
        if (!isScriptLoaded) {
          throw new Error('Square.js failed to load');
        }
  
        // Once script is loaded, initialize the payment
        initializePayment();
      } catch (error) {
        console.error('Error loading or initializing Square.js:', error);
        toast.error('Failed to load payment script. Please try again later.');
      }
    };
  
    initializeSquarePayment();
  
    return () => {
      if (cashAppPayInstance) {
        cashAppPayInstance.destroy();
      }
    };
  }, []);
  
  const initializePayment = async () => {
    try {
      if (!window.Square) {
        throw new Error('Square.js is not loaded');
      }
      const payments = window.Square.payments(appId, locationId);
      if (cashAppPayInstance) {
        cashAppPayInstance.destroy();
      }
      cashAppPayInstance = await initializeCashApp(payments);
      cashAppPayInstance.addEventListener('ontokenization', async function ({ detail }) {
        const tokenResult = detail.tokenResult;
        if (tokenResult.status === 'OK') {
          const paymentResults = await createPayment(tokenResult.token);
          displayPaymentResults('SUCCESS');
          console.debug('Payment Success', paymentResults);
        } else {
          let errorMessage = `${tokenResult.status}`;
          if (tokenResult.errors) {
            errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
          }
          displayPaymentResults('FAILURE');
          if (cashAppPayInstance) {
            cashAppPayInstance.destroy();
          }
          toast.error(`Your Payment ${errorMessage}`);
          initializePayment(); // Retry on failure
        }
      });
    } catch (error) {
      console.error('Error initializing payment:', error);
    }
  };

  function PaymentModel(){
    return(
      <Modal show centered>
        <Modal.Body>
          <div className='payment-cancel'>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
            </svg>
            <h5>Your Payment {status}</h5>
            <p>Please try Again</p>
            <a onClick={()=>{ setStatus(""); initializePayment(); }} className='text-black text-none'>Retry? </a>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div>
      <form id="payment-form">
        <h2 hidden>Pay ${props?.amount}</h2>
        <div id="cash-app-pay"></div>
      </form>
      <div hidden id="payment-status-container"></div>
      {/* {status && <PaymentModel/>} */}
    </div>
  );
};
CashPayComponent.propTypes = {
    amount: PropTypes.any,
    callBackInitPayment: PropTypes.func,
}
CashPayComponent.defaultProps = {
    callBackInitPayment: ()=>{},
}

export default CashPayComponent;
