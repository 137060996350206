/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PACKAGES_LIST_API_URL, CHECK_OUT_PACKAGE } from "../Shared/constant";
import BackgroundImage from "../../assets/img/SweepsCoins-WebsitePackages-Deliverable-Background.svg";
import { CallLogoutUser, getPackageLists, AddToCartPosters } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import DefaultProfile from "../../assets/img/DefaultProduct.jpg";

const Packages = () => {
    const Navigate = useNavigate();
    const { packageList } = useSelector((state) => state.allReducers);
    const dispatch = useDispatch();

    useEffect(() => {
        window.axios.get(`${PACKAGES_LIST_API_URL}/5000/1`, {
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }}).then(function (result) {
            if(result?.status === 200) {
                dispatch(getPackageLists(result?.data?.data));
            };
        }).catch(function (result) {
            if(result?.response?.status === 403) {
                dispatch(CallLogoutUser());
                localStorage.removeItem("access_tokens");
            };
        });
    }, []);

    // add to cart function
    const AddToCart = (elm) => {
        dispatch(AddToCartPosters(elm));
        setTimeout(() => Navigate(CHECK_OUT_PACKAGE), 100);
    };

    return(<section className="packagespage" style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="packagesContainer">
                        <h1>SWEEPS COINS PACKAGES</h1>
                        <div className="packages_wrapps">
                            {packageList.sort(function(a, b) {return a.price - b.price;})?.map((elm, index) => {
                                return(<div className="package_cols"  onClick={() => AddToCart(elm)} key={index}>
                                    <h4>{elm?.name}</h4>
                                    {elm?.package_image_path === null ? <img src={DefaultProfile} alt="image"/> : <img src={elm?.package_image_path} alt="image"/>}
                                    <div className="coinswrps">
                                        <h3>{elm?.title}</h3>
                                        <h5><b>{Number(elm?.sweep_coins).toLocaleString()}</b> SWEEPS COINS</h5>
                                        <button type="button" className="btn " onClick={() => AddToCart(elm)}>
                                            SELECT&nbsp;${Number(elm?.price).toLocaleString()} USD
                                        </button>
                                    </div>
                                </div>)})}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default Packages;