import React from 'react'
import { useParams } from 'react-router-dom';
import Checkout from '.';

export default function PackageCheckout(props) {
    const params = useParams();
    return (
        <Checkout {...props} order_id={params?.order_id}/>
    )
}
